import {createContext, useState, useEffect, useCallback, useContext} from "react";
import jwt_decode from "jwt-decode";
import {useHistory} from "react-router-dom";
import ConfigContext from "./ConfigContext";
import {useSnackbar} from "notistack";
import axios from "axios";


const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({children}) => {
    const {baseUrl} = useContext(ConfigContext)
    const [authTokens, setAuthTokens] = useState(() => localStorage.getItem("authTokens") ? JSON.parse(localStorage.getItem("authTokens")) : null);
    const [user, setUser] = useState(() => localStorage.getItem("authTokens") ? jwt_decode(localStorage.getItem("authTokens")) : null);
    const [permissions, setPermissions] = useState(() => localStorage.getItem("permissions") ? localStorage.getItem("permissions") : [])
    const [setupComplete, setSetupComplete] = useState(() => localStorage.getItem("setup_complete") ? localStorage.getItem("setup_complete") : 'true')
    const [subscriptionPlan, setSubscriptionPlan] = useState(() => localStorage.getItem("subscription") ? localStorage.getItem("subscription") : 'one')
    const [role, setRole] = useState(() => localStorage.getItem("role") ? localStorage.getItem("role") : 'employee')
    const [authLoading, setAuthLoading] = useState(false)
    const history = useHistory();
    const snackbar = useSnackbar()

    const api = axios.create({
        baseURL: baseUrl,
        headers: {"Content-Type": "application/json"}
    })

    const setAuthStorage = (data) => {
        setAuthTokens(data);
        const u = jwt_decode(data.access);
        setUser(u);
        setPermissions(u.permissions);
        setSetupComplete(u?.setup_complete)
        setSubscriptionPlan(u?.subscription_plan)
        setRole(u?.role)
        localStorage.setItem("authTokens", JSON.stringify(data));
        localStorage.setItem("permissions", u?.permissions);
        localStorage.setItem("setup_complete", u?.setup_complete);
        localStorage.setItem("subscription", u?.subscription_plan);
        localStorage.setItem("role", u?.role);
        return u?.role
    }

    const loginUser = (data) => {
        api.post('api/token/', JSON.stringify(data))
            .then(r => {
                const data = r?.data
                const role = setAuthStorage(data)
                if (role === 'employee') history.push('/reservations');
                else history.push("/");
            })
            .catch(e => {
                const status = e?.response?.status
                if (status === 401) snackbar.enqueueSnackbar('Hiba, helytelen email vagy jelszó!', {variant: 'error'})
                else snackbar.enqueueSnackbar('Hiba a bejelentkezésnél, próbálja újra később!', {variant: 'error'})
                console.log(e)
            })
    }

    const registerUser = (data) => {
        if (data.password !== data.password_again) {
            snackbar.enqueueSnackbar("Nem egyezik a két jelszó!", {variant: 'error'})
            return
        }
        setAuthLoading(true)
        api.post('register_new/', JSON.stringify(data))
            .then(r => {
                setAuthLoading(false)
                history.push('/success')
            })
            .catch(e => {
                setAuthLoading(false)
                const message = e?.response?.data?.message
                if (message) snackbar.enqueueSnackbar(message, {variant: 'error'})
                else snackbar.enqueueSnackbar('Váratlan hiba!', {variant: 'error'})
                console.log(e)
        })
    }

    const registerExistingUser = (data) => {
        if (data?.password !== data?.password_again) {
            snackbar.enqueueSnackbar('Nem egyezik a két jelszó!', {variant: 'error'})
            return
        }
        api.post('register_for_business/', JSON.stringify(data))
            .then(r => {
                setAuthLoading(false)
                history.push('/login')
            })
            .catch(e => {
                setAuthLoading(false)
                const message = e?.response?.data?.message
                if (message) snackbar.enqueueSnackbar(message, {variant: 'error'})
                else snackbar.enqueueSnackbar('Váratlan hiba!', {variant: 'error'})
                console.log(e)
            })
    }

    const logoutUser = useCallback(() => {
        setAuthTokens(null)
        setUser(null)
        setPermissions(null)
        setSetupComplete(null)
        setSubscriptionPlan(null)
        setRole(null)
        localStorage.removeItem("authTokens")
        localStorage.removeItem("permissions")
        localStorage.removeItem("setup_complete")
        localStorage.removeItem("subscription")
        localStorage.removeItem("role")
    }, []);

    const updateToken = useCallback( () => {
        api.post('api/token/refresh/', JSON.stringify({refresh: authTokens?.refresh}))
            .then(r => setAuthStorage(r?.data))
            .catch(e => logoutUser())
    }, [authTokens?.refresh, logoutUser])

    const forgotPassword = (data) => {
        setAuthLoading(true)
        api.post('password/send_link/', JSON.stringify(data))
            .then(r => {
                setAuthLoading(false)
                snackbar.enqueueSnackbar('Email kiküldve!', {variant: 'success'})
                history.push("/")
            })
            .catch(e => {
                setAuthLoading(false)
                snackbar.enqueueSnackbar('Hiba történt!', {variant: 'error'})
                console.log(e)
            })
    }

    const newPassword = (data) => {
        setAuthLoading(true)
        api.post('password/change_password/', JSON.stringify(data))
            .then(r => {
                setAuthLoading(false)
                snackbar.enqueueSnackbar('Sikeresen megváltoztatva!', {variant: 'success'})
                history.push("/login")
            })
            .catch(e => {
                setAuthLoading(true)
                snackbar.enqueueSnackbar('Hiba történt!', {variant: 'error'})
                console.log(e)
            })
    }

    const isSetupComplete = () => {
        if (setupComplete === 'false') return false
        if (setupComplete === 'true') return true
        return setupComplete
    }

    const contextData = {
        user: user,
        authTokens: authTokens,
        loginUser: loginUser,
        registerUser: registerUser,
        registerExistingUser: registerExistingUser,
        logoutUser: logoutUser,
        permissions: permissions,
        forgotPassword: forgotPassword,
        newPassword: newPassword,
        setupComplete: setupComplete,
        isSetupComplete: isSetupComplete,
        setSetupComplete: setSetupComplete,
        subscriptionPlan: subscriptionPlan,
        authLoading: authLoading,
        setAuthLoading: setAuthLoading,
        role: role,
    };

    useEffect(() => {
        const fourMinutes = 1000 * 60 * 4
        const interval = setInterval(() => {
            if (authTokens) {
                updateToken()
            }
        }, fourMinutes);
        return () => clearInterval(interval)
    }, [authTokens, updateToken])

    return <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>;
};

import React from "react";
import DinoAppBar from "../../components/AppBar";
import DinoFooter from "../../components/Footer";


const Base = ({children}) => {

    return <>
        <DinoAppBar/>
        {children}
        <DinoFooter/>
    </>
}

export default Base
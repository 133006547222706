import {Avatar, Box, Stack, useTheme} from "@mui/material";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import Button from "@mui/material/Button";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import thousandSeparator from "../../utils/numberUtils";
import useCallDataApi from "../../hooks/data";
import {useContext, useEffect, useState} from "react";
import ReceiptRoundedIcon from '@mui/icons-material/ReceiptRounded';
import AuthConfigContext from "../../context/AuthConfigContext";

const ReservationDetail = ({
                               r,
                               close,
                               destroy,
                               modify,
                               accept,
                               decline,
                               create_invoice,
                               download_invoice,
                               send_invoice
                           }) => {
    const theme = useTheme()
    const {billingAvailable} = useContext(AuthConfigContext)
    const {getData} = useCallDataApi('service-question')
    const [questions, setQuestions] = useState([])

    useEffect(() => {
        getData('get_for_business').then(q => setQuestions(q))
    }, [])

    const renderQuestion = (i, k, v) => {
        const question = questions?.find(q => q.id == k.split('_')[1])
        if (question?.answer_type === '3') v = v ? 'Igen' : 'Nem'
        return <p key={i}>{question?.question} - {v}</p>;
    }

    const renderExtraItem = (e) => {
        return <p key={e.id}>{e?.name} - {e?.quantity} db Összesen: {thousandSeparator(e?.quantity * e?.price)} Ft</p>;
    }

    const billingDataProvided = () => {
        return r?.billing_name && r?.billing_country && r?.billing_city && r?.billing_postal_code && r?.billing_address
    }

    const renderName = (status) => {
        const colorMap = {
            pending: '#ED8802',
            accepted: '#478A64',
            rejected: '#6F8E9A',
        }
        const backgroundMap = {
            pending: '#F4B867',
            accepted: '#9ED4B6',
            rejected: '#F1F4F5',
        }
        const statusMap = {
            accepted: 'Elfogadott',
            pending: 'Elfogadásra vár',
            rejected: 'Korábbi vagy elutasított',
        }
        return <Stack direction='row' spacing={2}>
            <Avatar sx={{
                width: 69,
                height: 69,
                background: backgroundMap[status],
                color: colorMap[status]
            }}>{r?.first_name?.charAt(0)}{r?.last_name?.charAt(0)}</Avatar>
            <Stack spacing={1} style={{selfAlign: 'center'}}>
                <Typography variant='headline5'>{r?.name}</Typography>
                <Typography variant='caption' style={{color: colorMap[status]}}>{statusMap[status]}</Typography>
            </Stack>
        </Stack>
    }

    const paymentMap = {
        card: 'Helyszíni fizetés',
        simple: 'Simplepay online',
    }

    const buttonStyle = {padding: 10, color: theme.palette.primary[400]}
    const textStyle = {color: theme.palette.primary[600]}

    return <div style={{padding: '48px'}}>
        <IconButton onClick={close} color="primary" aria-label="close" component="label"
                    style={{position: 'absolute', top: 10, left: 10}}>
            <CloseOutlinedIcon/>
        </IconButton>
        <Stack spacing={3}>
            {renderName(r?.display_status)}

            <Typography variant='subtitle1' style={textStyle}>Kliens adatai</Typography>
            <table className="reservation-detail-table">
                <tbody>
                <tr>
                    <td><Typography variant="subtitle2">Klines neve</Typography></td>
                    <td style={{textAlign: "right"}}>
                        <Typography variant='subtitle2' style={textStyle}>{r?.name}</Typography>
                    </td>
                </tr>
                <tr>
                    <td><Typography variant="subtitle2">Telefonszáma</Typography></td>
                    <td style={{textAlign: "right"}}>
                        <Typography variant='subtitle2' style={textStyle}>{r?.phone}</Typography>
                    </td>
                </tr>
                <tr>
                    <td><Typography variant="subtitle2">Email címe</Typography></td>
                    <td style={{textAlign: "right"}}>
                        <Typography variant='subtitle2' style={textStyle}>{r?.email}</Typography>
                    </td>
                </tr>
                </tbody>
            </table>
            <Typography variant='subtitle1' style={textStyle}>Foglalás adatai</Typography>
            <table className="reservation-detail-table">
                <tbody>
                <tr>
                    <td><Typography variant="subtitle2">Kategória</Typography></td>
                    <td style={{textAlign: "right"}}>
                        <Typography variant='subtitle2' style={textStyle}>{r?.price?.service?.category?.name}</Typography>
                    </td>
                </tr>
                <tr>
                    <td><Typography variant="subtitle2">Szolgáltatás</Typography></td>
                    <td style={{textAlign: "right"}}>
                        <Typography variant='subtitle2' style={textStyle}>{r?.price?.service?.name}</Typography>
                    </td>
                </tr>
                <tr>
                    <td><Typography variant="subtitle2">Szolgáltató</Typography></td>
                    <td style={{textAlign: "right"}}>
                        <Typography variant='subtitle2' style={textStyle}>{r?.price?.colleague?.name}</Typography>
                    </td>
                </tr>
                <tr>
                    <td><Typography variant="subtitle2">Ár</Typography></td>
                    <td style={{textAlign: "right"}}>
                        <Typography variant='subtitle2' style={textStyle}>{thousandSeparator(r?.price?.price)} Ft</Typography>
                    </td>
                </tr>
                <tr>
                    <td><Typography variant="subtitle2">Fizetés</Typography></td>
                    <td style={{textAlign: "right"}}>
                        <Typography variant='subtitle2' style={textStyle}>{paymentMap[r?.payment]}</Typography>
                    </td>
                </tr>
                <tr>
                    <td><Typography variant="subtitle2">Időpont</Typography></td>
                    <td style={{textAlign: "right"}}>
                        <Typography variant='subtitle2' style={textStyle}>{r?.date} • {r?.start_time} - {r?.end_time}</Typography>
                    </td>
                </tr>
                <tr>
                    <td><Typography variant="subtitle2">Megjegyzés</Typography></td>
                    <td style={{textAlign: "right"}}>
                        <Typography variant='subtitle2' style={textStyle}>{r?.comment}</Typography>
                    </td>
                </tr>
                </tbody>
            </table>
            <Typography variant='subtitle1' style={textStyle}>Számlázási adatok</Typography>
            <table className="reservation-detail-table">
                <tbody>
                <tr>
                    <td><Typography variant="subtitle2">Név</Typography></td>
                    <td style={{textAlign: "right"}}>
                        <Typography variant='subtitle2' style={textStyle}>{r?.billing_name}</Typography>
                    </td>
                </tr>
                <tr>
                    <td><Typography variant="subtitle2">Adószám</Typography></td>
                    <td style={{textAlign: "right"}}>
                        <Typography variant='subtitle2' style={textStyle}>{r?.billing_vat_number ? r?.billing_vat_number : '-'}</Typography>
                    </td>
                </tr>
                <tr>
                    <td><Typography variant="subtitle2">Cím</Typography></td>
                    <td style={{textAlign: "right"}}>
                        <Typography variant='subtitle2' style={textStyle}>{r?.billing_country?.label}, {r?.billing_postal_code} {r?.billing_city} {r?.billing_address}</Typography>
                    </td>
                </tr>
                </tbody>
            </table>
            {r?.extra_items?.length > 0 &&
                <Typography variant='subtitle1' style={textStyle}>
                    Extra tételek
                </Typography>
            }
            {r?.extra_items?.length > 0 && r?.extra_items?.map(e => renderExtraItem(e))}
            {r?.answers &&
                <Typography variant='subtitle1' style={textStyle}>
                    Szolgáltatás specifikus kérdések
                </Typography>
            }
            {r?.answers && Object.entries(r?.answers)?.map(([k, v], i) => renderQuestion(i, k, v))}
            {r?.answers && <Box sx={{height: '50px'}}/>}

            <Stack direction='row' spacing={2} sx={{position: 'fixed', bottom: 0, padding: '20px', background: '#fff'}}>
                {r?.display_status === 'accepted' &&
                    <>
                        <Button startIcon={<DeleteOutlineOutlinedIcon/>} onClick={destroy} variant='outlined'
                                style={buttonStyle}>Foglalás törlése</Button>
                        <Button startIcon={<CalendarMonthOutlinedIcon/>}
                                onClick={() => modify(r.id, '2023-02-26', '11:30', '12:00')} variant='contained'
                                style={{padding: 10}}>Időpont módosítása</Button>
                    </>
                }
                {r?.display_status === 'pending' &&
                    <>
                        <Button startIcon={<CloseOutlinedIcon/>} onClick={() => decline(r.id)} variant='outlined'
                                style={buttonStyle}>Foglalás
                            visszautasítása</Button>
                        <Button startIcon={<DoneOutlinedIcon/>} onClick={() => accept(r.id)} variant='contained'
                                style={{padding: 10}}>Foglalás elfogadása</Button>
                    </>
                }
                {r?.display_status === 'rejected' &&
                    <>
                        <Button startIcon={<DeleteOutlineOutlinedIcon/>} onClick={destroy} variant='outlined'
                                style={buttonStyle}>Foglalás törlése</Button>
                    </>
                }
                {(billingAvailable() && billingDataProvided()) &&
                    <>
                        {!r?.invoice_id ?
                            <Button startIcon={<ReceiptRoundedIcon/>} onClick={create_invoice} variant='outlined' style={buttonStyle}>
                                Számla elkészítése
                            </Button>
                            :
                            <>
                                <Button startIcon={<ReceiptRoundedIcon/>} onClick={download_invoice} variant='outlined' style={buttonStyle}>
                                    Számla letöltése
                                </Button>
                                <Button startIcon={<ReceiptRoundedIcon/>} onClick={send_invoice} variant='outlined' style={buttonStyle}>
                                    Számla elküldése
                                </Button>
                            </>
                        }
                    </>
                }
                {!billingDataProvided() && <Button startIcon={<ReceiptRoundedIcon/>} disabled variant='outlined'>
                    Nem adott meg számlázási adatokat
                </Button>}
            </Stack>
        </Stack>

    </div>
}

export default ReservationDetail
// const getFormData = (form) => {
//     if (!form.checkValidity()) {
//         form.reportValidity()
//         return
//     }
//     const formData = new FormData(form)
//     let object = {};
//     formData.forEach((value, key) => {
//         if (value !== "") object[key] = value
//         if (value === "on") object[key] = true
//     });
//     return object;
// }

const truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
}

const getFormData = (form) => {
    if (!form.checkValidity()) {
        form.reportValidity()
        return
    }
    const formData = new FormData(form)
    let object = {};
    formData.forEach((value, key) => {
        if (key.startsWith('logicalfield-')) {
            const newKey = key.replace('logicalfield-', '')
            object[newKey] = value === 'true'
        } else if (key.startsWith('nullable-')) {
            const newKey = key.replace('nullable-', '')
            object[newKey] = value === '' ? null : value
        } else {
            if (value !== "") object[key] = value
        }
    });
    return object
}

const getFormDataNative = (form, extra=[]) => {
    if (!form.checkValidity()) {
        form.reportValidity()
        return
    }
    let formData = new FormData(form);
    // remove empty image for update
    for (let item of formData.entries()) {
        if (typeof item[1] === 'object' && item[1].size === 0) formData.delete(item[0])
    }
    extra.forEach(e => formData.append(e.key, e.value));
    return formData;
}

const getFormDataWithImage = (form, img, extra) => {
    if (!form.checkValidity()) {
        form.reportValidity()
        return
    }
    let formData = new FormData(form)
    if (img) formData.append("image", img, img.name);
    extra.forEach(e => formData.append(e.key, e.value));
    return formData;
}

export {getFormData, getFormDataNative, getFormDataWithImage, truncateText}
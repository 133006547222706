const serviceTemplate = {
    business: 1,
    description: "",
    id: 1,
    image: "",
    price: 1,
    name: "",
    is_public: false
}

export default serviceTemplate
import {Checkbox, FormControlLabel, FormGroup, NativeSelect, Stack, TextField, useTheme} from "@mui/material";
import Typography from "@mui/material/Typography";

const QuestionData = ({reference, obj}) => {
    const theme = useTheme()

    return <form ref={reference}>
        <Stack spacing={1}>
            <Typography variant='caption' color={theme.palette.primary[500]}>Kérdés*</Typography>
            <TextField required variant='standard' name='question' multiline minRows={5} defaultValue={obj?.question || ''}/>
            <Typography variant='caption' color={theme.palette.primary[500]}>Kérdés típusa*</Typography>
            <NativeSelect inputProps={{name: 'answer_type', id: 'uncontrolled-answer',}} defaultValue={obj?.answer_type || '1'}>
                <option value='1'>Szabad szöveges válasz</option>
                <option value='2'>Szám válasz</option>
                <option value='3'>Igen/nem</option>
            </NativeSelect>
            <FormGroup>
                <FormControlLabel control={<Checkbox name='required' defaultChecked={obj?.required || true} />} label={<Typography variant='caption' color={theme.palette.primary[600]}>kötelező kitölteni a klienseknek</Typography>} />
            </FormGroup>
        </Stack>
    </form>
}

export default QuestionData
import AuthBase from "../../AuthBase";
import {Avatar, Skeleton, Stack, useTheme} from "@mui/material";
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import useCallDataApi from "../../../hooks/data";
import {useContext, useEffect, useRef, useState} from "react";
import Typography from "@mui/material/Typography";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfigContext from "../../../context/ConfigContext";
import DinoDialog from "../../../components/Dialog";
import Button from "@mui/material/Button";
import {getFormDataNative} from "../../../utils/FormUtils";
import CircleButton from "../../../components/CircleButton";
import {useHistory, useParams} from "react-router-dom";
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import * as React from "react";
import Box from "@mui/material/Box";
import ServiceData from "./serviceData";
import Loading from "../../../components/Loading";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ErrorContext from "../../../context/ErrorContext";


const Service = ({id, name, visible, description, image, edit, destroy}) => {
    const theme = useTheme()

    return <Stack sx={{flexDirection: {xs: 'column', sm: 'column', md: 'row'}}} spacing={2} style={{width: '100%', justifyContent: 'space-between'}}>
        <Stack direction='row' spacing={2}>
            <Avatar src={image} sx={{width: 60, height: 60, border: '2px solid', alignSelf: 'center', borderColor: theme.palette.primary[200]}}/>
            <Box sx={{alignSelf: 'center'}}>
                <Typography variant="subtitle1" style={{color: theme.palette.primary[600]}}>{name}</Typography>
                <Typography variant="body2" flexWrap style={{color: '#536B74'}}>{description}</Typography>
            </Box>
        </Stack>

        <Stack style={{alignSelf: 'center'}} spacing={1} direction='row' sx={{display: {xs: 'none', sm: 'none', md: 'flex'}}}>
            <Typography variant='button' align='center' noWrap style={{ color: visible ? theme.palette.primary[600] : '#6F8E9A', alignSelf: 'center', }}>
                {visible ? <Box sx={{display: 'flex'}}><RemoveRedEyeRoundedIcon sx={{alignSelf: 'center', marginRight: 1}}/><Typography sx={{alignSelf: 'center'}} variant='caption'>látható</Typography></Box> : <Box sx={{display: 'flex'}}><VisibilityOffRoundedIcon sx={{alignSelf: 'center'}}/> <Typography sx={{alignSelf: 'center'}} variant='caption'>rejtett</Typography></Box>}
            </Typography>
            <CircleButton icon={<DeleteIcon/>} onClick={destroy}/>
            <CircleButton icon={<EditIcon/>} onClick={edit} inverted/>
        </Stack>

        <Stack spacing={1} sx={{display: {xs: 'flex', sm: 'flex', md: 'none'}}}>
            <Typography variant='button' align='center' noWrap style={{ color: visible ? theme.palette.primary[600] : '#6F8E9A', alignSelf: 'center', }}>
                {visible ? <Box sx={{display: 'flex'}}><RemoveRedEyeRoundedIcon sx={{alignSelf: 'center'}}/><Typography sx={{alignSelf: 'center'}} variant='caption'>látható</Typography></Box> : <Box sx={{display: 'flex'}}><VisibilityOffRoundedIcon sx={{alignSelf: 'center'}}/> <Typography sx={{alignSelf: 'center'}} variant='caption'>rejtett</Typography></Box>}
            </Typography>
            <Button variant='outlined' endIcon={<DeleteIcon/>} onClick={destroy}>Törlés</Button>
            <Button variant='contained' endIcon={<EditIcon/>} onClick={edit}>Szerkesztés</Button>
        </Stack>
    </Stack>
}


const Services = () => {
    const [loading, setLoading] = useState(true)
    const [category, setCategory] = useState({})
    const [services, setServices] = useState([])
    const [current, setCurrent] = useState(1)
    const [newOpen, setNewOpen] = useState(false)
    const [deleteOpen, setDeleteOpen] = useState(false)
    const {
        getData: fetchServices,
        deleteData: deleteService,
        formDataCreateData: createService
    } = useCallDataApi('service')
    const {getData: getCategory} = useCallDataApi('category')
    const serviceForm = useRef(null)
    const params = useParams()
    const history = useHistory()
    const {baseUrl} = useContext(ConfigContext)
    const {setPictureError} = useContext(ErrorContext)


    const buttons = [{
        name: 'Új szolgáltatás hozzáadása',
        props: {
            variant: 'contained',
            size: 'large',
            endIcon: <AddCircleOutlinedIcon/>,
            onClick: () => {
                setNewOpen(true)
            }
        }
    }]

    const filterButtons = [
        {
            name: 'Új szolgáltatás hozzáadása',
            props: {
                variant: 'contained',
                endIcon: <AddCircleOutlinedIcon/>,
                onClick: () => {
                    setNewOpen(true)
                }
            }
        }
    ]
    const filterButtonsMobile = [
        {
            name: 'Új szolgáltatás',
            props: {
                variant: 'contained',
                size: 'small',
                endIcon: <AddCircleOutlinedIcon/>,
                onClick: () => {
                    setNewOpen(true)
                }
            }
        }
    ]

    const getService = (id) => {
        return services.find(c => c.id === id)
    }

    const newService = () => {
        setLoading(true)
        const formDataNative = getFormDataNative(serviceForm.current, [{key: 'category', value: params.id}]);
        if (formDataNative){
            const noPicture = !formDataNative?.has('image')
            if (noPicture) {
                setPictureError(true)
                setLoading(false)
                return false
            }
            createService(formDataNative)
                .then(c => {
                    if (c) setServices([...services, c])
                })
                .finally(() => setLoading(false))
            return true
        }
        setLoading(false)
        return false
    }

    const removeService = (id) => {
        setLoading(true)
        deleteService(id)
            .then(r => setServices([...services.filter(s => s.id !== id)]))
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        const getData = async () => {
            const [c, s] = await Promise.all([
                getCategory(params.id),
                fetchServices(`${params.id}/get_for_category`)
            ])
            if (s) setServices(s)
            if (c) setCategory(c)
            setLoading(false)
        }
        getData().catch(r => console.log(r))
    }, [])

    const deleteActions = <>
        <Button variant='outlined' style={{padding: 15}} onClick={() => setDeleteOpen(false)}>mégsem</Button>
        <Button variant='contained' style={{padding: 15}} onClick={() => {
            setDeleteOpen(false)
            removeService(current)
        }} endIcon={<DeleteRoundedIcon/>}>Törlés</Button>
    </>

    const newActions = <>
        <Button variant='outlined' style={{padding: 15}} onClick={() => setNewOpen(false)}>mégsem</Button>
        <Button variant='contained' style={{padding: 15}} onClick={() => {
            if (newService()) setNewOpen(false)
        }} endIcon={<AddCircleOutlinedIcon/>}>Hozzáadás</Button>
    </>

    return <AuthBase label={category?.name} buttons={buttons} filterButtonsMobile={filterButtonsMobile}>
        <div className="main">
            <Button variant='text' startIcon={<ArrowBackIosNewRoundedIcon/>} onClick={() => history.push(`/categories`)}>vissza</Button>
            <Typography variant='subtitle2' style={{color: '#536B74'}} gutterBottom>{services.length} szolgáltatás</Typography>
            <div className="white-card">
                {loading ? <Skeleton variant="circular">
                        <Avatar />
                </Skeleton> :
                <Stack spacing={2}>
                    {services.map((s, i) =>
                        <Service key={`service_${i}`}
                                 name={s.name}
                                 description={s.description}
                                 visible={s.is_public}
                                 image={s.image.startsWith('http') ? s.image : baseUrl + s.image}
                                 numberOfServices={s.number_of_services}
                                 edit={() => history.push(`/service-detail/${s.id}`)}
                                 destroy={() => {
                                     setCurrent(s.id)
                                     setDeleteOpen(true)
                                 }}/>
                    )}
                </Stack>
                }
            </div>
        </div>

        <DinoDialog open={newOpen}
                    title='Új szolgáltatás hozzáadása'
                    subtitle='Add meg a szolgáltatás nevét és leírását!'
                    actions={newActions}
                    handleClose={() => setNewOpen(false)}
                    >
            <ServiceData reference={serviceForm}/>
        </DinoDialog>

        <DinoDialog open={deleteOpen}
                    title={`Biztosan kitörli ezt: ${getService(current)?.name}`}
                    subtitle='Az összes hozzá tartozó adat törlődni fog, foglalások, árak...!'
                    actions={deleteActions}
                    handleClose={() => setDeleteOpen(false)}>
        </DinoDialog>

        <Loading isLoading={loading}/>

    </AuthBase>
}

export default Services
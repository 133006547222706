import {Stack} from "@mui/material";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {useContext, useEffect, useState} from "react";
import useCallDataApi from "../../hooks/data";
import ConfigContext from "../../context/ConfigContext";
import CircleButton from "../CircleButton";
import ReservationContext from "../../context/ReservationContext";

const Category = ({category}) => {
    const {baseUrl} = useContext(ConfigContext)
    return <div style={{display: 'flex', gap: '20px'}}>
        <Avatar src={baseUrl + category?.picture} sx={{width: 70, height: 70, alignSelf: 'center'}}/>
        <div className="text-container">
            <Typography align="left" variant="subtitle1">{category.name}</Typography>
            <Typography align="left" variant="body2">{category.description}</Typography>
        </div>
    </div>
}

const SelectCategory = () => {
    const {getData} = useCallDataApi('category')
    const {next, selected} = useContext(ReservationContext)
    const [categories, setCategories] = useState([])

    useEffect(() => {
        getData(`get_for_business/`).then(r => setCategories(r))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const proceed = (c) => next({...selected, category: c})

    return <div className="data-container">
        <Stack spacing={{xs: 2}}>
            {categories.map((c, i) =>
                <Box className="data-select" key={`data_${i}`}>
                    <Stack direction='row' justifyContent='space-between'>
                        <Category category={c}/>
                        <CircleButton icon={<NavigateNextIcon/>} onClick={() => proceed(c)} sx={{width: 70, height: 70, justifySelf: 'flex-end'}} variant='outlined' />
                    </Stack>
                </Box>)}
        </Stack>
    </div>
}
export default SelectCategory
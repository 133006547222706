import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material";

const DialogDetail = ({r}) => {
    const theme = useTheme()
  return <><Typography variant='subtitle1' color={theme.palette.primary[600]}>Foglalás adatai</Typography>
      <table className="reservation-detail-table" style={{width: '100%'}}>
          <tbody>
          <tr>
              <td><Typography variant='subtitle2'>Kliens neve</Typography></td>
              <td><Typography variant='subtitle2' color={theme.palette.primary[600]}>{r?.name}</Typography></td>
          </tr>
          <tr>
              <td><Typography variant='subtitle2'>Kategória</Typography></td>
              <td><Typography variant='subtitle2' color={theme.palette.primary[600]}>{r?.price?.service?.category?.name}</Typography></td>
          </tr>
          <tr>
              <td><Typography variant='subtitle2'>Szolgáltatás</Typography></td>
              <td><Typography variant='subtitle2' color={theme.palette.primary[600]}>{r?.price?.service?.name}</Typography></td>
          </tr>
          <tr>
              <td><Typography variant='subtitle2'>Szolgáltató</Typography></td>
              <td><Typography variant='subtitle2' color={theme.palette.primary[600]}>{r?.price?.colleague?.name}</Typography></td>
          </tr>
          <tr>
              <td><Typography variant='subtitle2'>Ár</Typography></td>
              <td><Typography variant='subtitle2' color={theme.palette.primary[600]}>{r?.price?.price} Ft</Typography></td>
          </tr>
          <tr>
              <td><Typography variant='subtitle2'>Fizetés</Typography></td>
              <td><Typography variant='subtitle2' color={theme.palette.primary[600]}>{r?.payment}</Typography></td>
          </tr>
          <tr>
              <td><Typography variant='subtitle2'>Időpont</Typography></td>
              <td><Typography variant='subtitle2' color={theme.palette.primary[600]}>{r?.date} • {r?.start_time} - {r?.end_time}</Typography></td>
          </tr>
          <tr>
              <td><Typography variant='subtitle2'>Megjegyzés</Typography></td>
              <td><Typography variant='subtitle2' color={theme.palette.primary[600]}>{r?.comment}</Typography></td>
          </tr>
          </tbody>
      </table>
  </>
}

export default DialogDetail
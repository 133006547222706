import {createContext, useState} from "react";
import useCallDataApi from "../hooks/data";
import Loading from "../components/Loading";
import DinoDialog from "../components/Dialog";
import BillingBase from "../pages/settings/BillingBase";
import {Button} from "@mui/material";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import BillingoDisplay from "../pages/settings/billingo/BillingoDisplay";
import BillingoDetails from "../pages/settings/billingo/BillingoDetails";


const BillingContext = createContext();

export default BillingContext;

export const BillingProvider = ({ billingServices, setBillingServices, children }) => {
    const steps = ['billing_service', 'data']
    const {postData} = useCallDataApi('business')
    const [activeStep, setActiveStep] = useState(0)
    const [selected, setSelected] = useState({})
    const [current, setCurrent] = useState(-1)
    const [addNewBillingDetail, setAddNewBillingDetail] = useState(false)
    const [deleteBillingDetail, setDeleteBillingDetail] = useState(false)
    const [editBillingDetail, setEditBillingDetail] = useState(false)
    const [loading, setLoading] = useState(false)

    const back = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
        const keyToRemove = steps[activeStep]
        delete selected[keyToRemove]
    }

    const next = (selectedObj) => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
        setSelected({...selected, ...selectedObj})
    }

    const renderBillingo = (data) => <BillingoDisplay key={data?.id} id={data?.id} name={data?.name} active={data?.active}/>

    const billingServiceMap = {
        billingo: renderBillingo
    }

    const addBillingService = (data) => {
        setLoading(true)
        postData('add_new_billing_service/', {...selected, ...data})
            .then(s => {
                setAddNewBillingDetail(false)
                setBillingServices(s)
            })
            .catch(e => console.log(e))
            .finally(() => setLoading(false))
    }

    const editBillingService = (data) => {
        setLoading(true)
        postData('edit_billing_service/', {billing_service: current, data: data})
            .then(s => setBillingServices(s))
            .finally(() => {
                setEditBillingDetail(false)
                setLoading(false)
            })
    }

    const setBillingServiceActive = (id) => {
        setLoading(true)
        postData('set_billing_service_as_active/', {billing_service: id})
            .then(s => setBillingServices(s))
            .finally(() => setLoading(false))
    }

    const deactivateBillingService = (id) => {
        setLoading(true)
        postData('deactivate_billing_service/', {billing_service: id})
            .then(s => setBillingServices(s))
            .finally(() => setLoading(false))
    }

    const removeBillingDetail = (id) => {
        setLoading(true)
        postData('delete_billing_detail/', {billing_service: id})
            .then(s => setBillingServices(s))
            .finally(() => setLoading(false))
    }

    const billingDeleteActions = <>
        <Button variant='outlined' style={{padding: 15}} onClick={() => setDeleteBillingDetail(false)}>mégsem</Button>
        <Button variant='contained' style={{padding: 15}} onClick={() => {
            setDeleteBillingDetail(false)
            removeBillingDetail(current)
        }} endIcon={<DeleteForeverRoundedIcon/>}>Számlázási adatok törlése</Button>
    </>

    // const billingEditActions = <>
    //     <Button variant='outlined' style={{padding: 15}} onClick={() => setEditBillingDetail(false)}>mégsem</Button>
    //     <Button variant='contained' style={{padding: 15}} onClick={() => {
    //         setEditBillingDetail(false)
    //     }} endIcon={<DeleteForeverRoundedIcon/>}>Számlázási adatok szerkesztése</Button>
    // </>

    const contextData = {
        steps,
        activeStep,
        selected,
        setSelected,
        back,
        next,
        addBillingService,
        setBillingServiceActive,
        deactivateBillingService,
        removeBillingDetail,
        setDeleteBillingDetail,
        setEditBillingDetail,
        setAddNewBillingDetail,
        setCurrent,
        billingServiceMap
    }

    return <BillingContext.Provider value={contextData}>
        {children}
        <DinoDialog open={addNewBillingDetail}
                    handleClose={() => setAddNewBillingDetail(false)}
                    title='Új számlázási adatok hozzáadása'
                    subtitle='Válassz az integrált számlázási lehetőségek közül!'
        >
            <BillingBase/>
        </DinoDialog>

        <DinoDialog open={deleteBillingDetail}
                    handleClose={() => setDeleteBillingDetail(false)}
                    title='Számlázási adatok törlése'
                    subtitle='Amennyiben kitörlöd a számlázási adatokat nem fogod tudni megtekinteni a rendszerből eddig kiallított számláidat!!!'
                    actions={billingDeleteActions}
        >
        </DinoDialog>

        <DinoDialog open={editBillingDetail}
                    handleClose={() => setEditBillingDetail(false)}
                    title='Számlázási adatok szerkesztése'
                    subtitle='Számlázási adatok szerkesztése'
                    // actions={billingEditActions}
        >
            <BillingoDetails dataHandler={editBillingService} existingData={billingServices.find(b => b?.id === current)}/>
        </DinoDialog>
        <Loading isLoading={loading}/>
    </BillingContext.Provider>
}
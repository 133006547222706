const business = {
    category_heading: "",
    category_text : "",
    colleagues_heading : "",
    currency : "HUF",
    name: "",
    country: {code:"HU",label:"Magyarország",phone:"36"},
    state : "",
    city : "",
    zip : "",
    address : "",
    vat_number : "",
    incorporation_type : "company",
    colleagues_text : "",
    color : "",
    contact_heading : "",
    contact_text : "",
    facebook : "",
    hero_heading : "",
    hero_text: "",
    id: "",
    instagram: "",
    maps: "",
    only_links: false,
    payment_type: "simple",
    payment_on: false,
    public_address: "",
    public_email: "",
    public_logo: "",
    public_name: "",
    public_phone: "",
    redirect_url: "",
    subdomain: "",
    subscription: "none",
    twitter: "",
    youtube: "",
    payment_credentials: {merchant_id: "", secret_key: ""},
    payment_authorized: false,
    invoice_service: ''
}

export default business
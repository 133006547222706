import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import {useContext} from "react";
import {useTheme} from "@mui/material";
import ReservationContext from "../../context/ReservationContext";

const PaymentData = () => {
    const {paymentData, setPaymentData, selected, business} = useContext(ReservationContext)
    const theme = useTheme()
    const style = {color: theme.palette.primary[600]}

    return <div className="data-container-fill">
        <Typography variant="headline6" gutterBottom style={style}>Fizetés</Typography>
        <form>
            <FormControl>
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="card"
                    name="payment"
                    value={paymentData.payment}
                    onChange={e => setPaymentData({payment: e.target.value})}
                >
                    {/*{(business?.payment_authorized && selected?.service?.is_pay_online) &&*/}
                    {/*    <FormControlLabel value="simple" control={<Radio/>}*/}
                    {/*                      label="Online fizetés SimplePay rendszerrel"/>*/}
                    {/*}*/}
                    <FormControlLabel value="card" control={<Radio/>} label="Fizetés a helyszínen"/>
                </RadioGroup>
            </FormControl>
        </form>
    </div>
}

export default PaymentData
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";


export default function DinoDialog({children, title, subtitle, open, handleClose, actions}) {
    const theme = useTheme()

    return <Dialog open={open} onClose={handleClose} fullWidth maxWidth='lg' PaperProps={{style: {borderRadius: '16px', padding: 4}}}>
        <IconButton onClick={handleClose} color="primary" aria-label="close" component="label"
                    style={{position: 'absolute', top: 10, right: 10}}>
            <CloseOutlinedIcon/>
        </IconButton>
        <DialogTitle>
            <Typography variant='headline5' style={{color: theme.palette.primary[600]}}>{title}</Typography>
            <Typography variant='body2' style={{color: '#536B74'}}>{subtitle}</Typography>
        </DialogTitle>
        <DialogContent>
            {children}
        </DialogContent>
        <DialogActions>
            {actions}
        </DialogActions>
    </Dialog>
}
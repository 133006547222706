import Typography from "@mui/material/Typography";
import {Autocomplete, Box, Stack, TextField, useTheme} from "@mui/material";
import {useContext} from "react";
import ReservationContext from "../../context/ReservationContext";
import ErrorHandlingTextField, {validateNumber, validateRequired} from "../ErrorHandlingTextField";
import countries from "../../utils/countries";
import BasicTabs from "../CusomTabPanel";

const BillingData = () => {
    const {billingData, setBillingData, error, setError, billingDetailsMandatory} = useContext(ReservationContext)
    const theme = useTheme()
    const style = {color: theme.palette.primary[600]}

    const checkError = (v, name) => {
        if (billingDetailsMandatory()) setError({...error, [name]: v});
    }

    const tabs = [{
        name: 'Magánszemély', content: <Stack spacing={1}>
            <Typography variant='caption' sx={{color: theme.palette.primary[500]}}>Számlázási név{billingDetailsMandatory() && '*'}</Typography>
            <ErrorHandlingTextField check={v => checkError(v, 'billing_name')} validators={[validateRequired]}
                                    value={billingData?.billing_name || ''} variant='standard' name="billing_name"
                                    onValChange={e => setBillingData({...billingData, billing_name: e.target.value})}/>

            <Typography variant='caption' sx={{color: theme.palette.primary[500]}}>Ország{billingDetailsMandatory() && '*'}</Typography>
            <Autocomplete
                id="country-select-person"
                sx={{width: '100%'}}
                options={countries}
                autoHighlight
                value={billingData?.billing_country || null}
                onChange={(event, newValue) => {
                    setBillingData({...billingData, billing_country: newValue});
                }}
                isOptionEqualToValue={(option, value) => option.code === value.code}
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                    <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                        <img
                            loading="lazy"
                            width="20"
                            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                            alt=""
                        />
                        {option.label} ({option.code})
                    </Box>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant='standard'
                        fullWidth
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                    />
                )}
            />

            <Stack spacing={1} direction='row' sx={{width: '100%'}}>
                <Stack spacing={1} sx={{width: '100%'}}>

                    <Typography variant='caption' sx={{color: theme.palette.primary[500]}}>Irányítószám{billingDetailsMandatory() && '*'}</Typography>
                    <ErrorHandlingTextField check={v => checkError(v, 'billing_postal_code')}
                                            validators={[validateRequired, validateNumber]}
                                            value={billingData?.billing_postal_code || ''} variant='standard'
                                            name="billing_postal_code"
                                            onValChange={e => setBillingData({...billingData, billing_postal_code: e.target.value})}/>
                </Stack>

                <Stack spacing={1} sx={{width: '100%'}}>
                    <Typography variant='caption' sx={{color: theme.palette.primary[500]}}>Város{billingDetailsMandatory() && '*'}</Typography>
                    <ErrorHandlingTextField check={v => checkError(v, 'billing_city')}
                                            validators={[validateRequired]}
                                            value={billingData?.billing_city || ''} variant='standard' name="billing_city"
                                            onValChange={e => setBillingData({...billingData, billing_city: e.target.value})}/>
                </Stack>
            </Stack>

            <Typography variant='caption' sx={{color: theme.palette.primary[500]}}>Cím{billingDetailsMandatory() && '*'}</Typography>
            <ErrorHandlingTextField check={v => checkError(v, 'billing_address')}
                                    validators={[validateRequired]}
                                    value={billingData?.billing_address || ''} variant='standard' name="billing_address"
                                    onValChange={e => setBillingData({...billingData, billing_address: e.target.value})}/>
        </Stack>
    },

        {
            name: 'Cég', content: <Stack spacing={1}>
                <Typography variant='caption' sx={{color: theme.palette.primary[500]}}>Számlázási név{billingDetailsMandatory() && '*'}</Typography>
                <ErrorHandlingTextField check={v => checkError(v, 'billing_name')}
                                        validators={[validateRequired]}
                                        value={billingData?.billing_name || ''} variant='standard' name="billing_name"
                                        onValChange={e => setBillingData({...billingData, billing_name: e.target.value})}/>

                <Typography variant='caption' sx={{color: theme.palette.primary[500]}}>Adószám{billingDetailsMandatory() && '*'}</Typography>
                <ErrorHandlingTextField check={v => checkError(v, 'vat_number')}
                                        validators={[]}
                                        value={billingData?.vat_number || ''} variant='standard' name="vat_number"
                                        onValChange={e => setBillingData({...billingData, vat_number: e.target.value})}/>

                <Typography variant='caption' sx={{color: theme.palette.primary[500]}}>Ország{billingDetailsMandatory() && '*'}</Typography>
                <Autocomplete
                    id="country-select-company"
                    sx={{width: '100%'}}
                    options={countries}
                    autoHighlight
                    value={billingData?.billing_country || null}
                    onChange={(event, newValue) => {
                        setBillingData({...billingData, billing_country: newValue});
                    }}
                    isOptionEqualToValue={(option, value) => option.code === value.code}
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option) => (
                        <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                            <img
                                loading="lazy"
                                width="20"
                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                alt=""
                            />
                            {option.label} ({option.code})
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant='standard'
                            fullWidth
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                        />
                    )}
                />

                <Stack spacing={1} direction='row' sx={{width: '100%'}}>
                    <Stack spacing={1} sx={{width: '100%'}}>

                        <Typography variant='caption' sx={{color: theme.palette.primary[500]}}>Irányítószám{billingDetailsMandatory() && '*'}</Typography>
                        <ErrorHandlingTextField check={v => checkError(v, 'billing_postal_code')}
                                                validators={[validateRequired, validateNumber]}
                                                value={billingData?.billing_postal_code || ''} variant='standard'
                                                name="billing_postal_code"
                                                onValChange={e => setBillingData({
                                                    ...billingData,
                                                    billing_postal_code: e.target.value
                                                })}/>
                    </Stack>

                    <Stack spacing={1} sx={{width: '100%'}}>
                        <Typography variant='caption' sx={{color: theme.palette.primary[500]}}>Város{billingDetailsMandatory() && '*'}</Typography>
                        <ErrorHandlingTextField check={v => checkError(v, 'billing_city')}
                                                validators={[validateRequired]}
                                                value={billingData?.billing_city || ''} variant='standard' name="billing_city"
                                                onValChange={e => setBillingData({...billingData, billing_city: e.target.value})}/>
                    </Stack>
                </Stack>

                <Typography variant='caption' sx={{color: theme.palette.primary[500]}}>Cím{billingDetailsMandatory() && '*'}</Typography>
                <ErrorHandlingTextField check={v => checkError(v, 'billing_address')}
                                        validators={[validateRequired]}
                                        value={billingData?.billing_address || ''} variant='standard' name="billing_address"
                                        onValChange={e => setBillingData({...billingData, billing_address: e.target.value})}/>

            </Stack>
        }]
    return <div className="data-container-fill">
        <Typography variant="headline6" gutterBottom style={style}>Számlázási adatok</Typography>
        <BasicTabs tabs={tabs}/>
    </div>
}

export default BillingData
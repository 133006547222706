import {useContext, useState} from "react";
import BillingContext from "../../context/BillingContext";
import BillingStepper from "../../components/BillingStepper";
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import {Box, Button, FormControl, MenuItem, Select, Stack, useTheme} from "@mui/material";
import Typography from "@mui/material/Typography";
import BillingoDetails from "./billingo/BillingoDetails";

const BillingServiceSelect = () => {
    const theme = useTheme()
    const {next} = useContext(BillingContext)
    const [billingService, setBillingService] = useState('')
    const proceed = () => next({billing_service: billingService})

    return <>
        <Stack spacing={1} marginTop={2} marginBottom={2}>
            <Typography variant='caption' color={theme.palette.primary[500]}>Számlázóprogram</Typography>
            <FormControl fullWidth>
                <Select
                    id="currency-select"
                    value={billingService}
                    variant='standard'
                    label="Fizetési típus"
                    onChange={(event) => setBillingService(event.target.value)}
                >
                    <MenuItem value='billingo'>Billingo</MenuItem>
                    {/*<MenuItem value='eur'>EUR</MenuItem>*/}
                </Select>
            </FormControl>
        </Stack>
        <Box sx={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
            <Button disabled={billingService === ''} variant='contained' onClick={proceed} endIcon={<NavigateNextRoundedIcon/>}>Adatok megadása</Button>
        </Box>
    </>
}

const BillingBase = () => {
    const {activeStep, selected, addBillingService} = useContext(BillingContext)

    const billingServiceMap = {
        billingo: <BillingoDetails dataHandler={addBillingService}/>
    }

    const stepMap = [
        <BillingServiceSelect/>,
        billingServiceMap[selected?.billing_service]
    ]

    return <>
        <BillingStepper/>
        {stepMap[activeStep]}
    </>
}

export default BillingBase
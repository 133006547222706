import React, {useContext} from "react";
import DinoFooter from "../../components/Footer";
import DinoWizardAppBar from "../../components/WizardAppBar";
import {useEffect} from "react";
import {useHistory} from "react-router-dom";
import AuthContext from "../../context/AuthContext";


const WizardBase = ({children}) => {
    const {isSetupComplete} = useContext(AuthContext)
    const history = useHistory()
    const currentPath = window.location.pathname
    const allowedPaths = ['/payment-over', '/card-registration']

    useEffect(() => {
        if (!isSetupComplete() && !allowedPaths.includes(currentPath)) {
            history.push('/card-registration')
        }
        else if (isSetupComplete() && allowedPaths.includes(currentPath)) {
            history.push('/')
        }
    }, []);

    return <>
        <DinoWizardAppBar/>
        {children}
        <DinoFooter/>
    </>
}

export default WizardBase
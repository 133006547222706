import {Stack} from "@mui/material";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {useContext, useEffect, useState} from "react";
import useCallDataApi from "../../hooks/data";
import ConfigContext from "../../context/ConfigContext";
import CircleButton from "../CircleButton";
import ReservationContext from "../../context/ReservationContext";

const Service = ({service}) => {
    const {baseUrl} = useContext(ConfigContext)
    return <div style={{display: 'flex', gap: '20px'}}>
        <Avatar src={baseUrl + service?.image} sx={{width: 70, height: 70, alignSelf: 'center'}}/>
        <div className="text-container">
            <Typography align="left" variant="subtitle1">{service.name}</Typography>
            <Typography align="left" variant="body2">{service.description}</Typography>
            <Typography align="left" variant="body1">{service.length} perc</Typography>
        </div>
    </div>
}

const SelectService = () => {
    const {getData} = useCallDataApi('service')
    const [services, setServices] = useState([])
    const {next, selected} = useContext(ReservationContext)

    useEffect(() => {
        getData(`${selected?.category?.id}/get_for_category/`).then(r => setServices(r))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <div className="data-container">
        <Stack spacing={{xs: 2}}>
            {services.map((service, i) =>
                <Box className="data-select" key={`data_${i}`}>
                    <Stack direction='row' justifyContent='space-between'>
                        <Service service={service}/>
                        <CircleButton icon={<NavigateNextIcon/>} onClick={() => next({...selected, service: service})}
                            sx={{width: 70, height: 70, justifySelf: 'flex-end'}} variant='outlined' />
                    </Stack>
                </Box>)}
        </Stack>
    </div>
}

export default SelectService
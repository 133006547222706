import {useContext} from "react";
import BillingContext from "../../context/BillingContext";
import {Stack} from "@mui/material";

const BillingServices = ({billingServices}) => {
    const {billingServiceMap} = useContext(BillingContext)
    return <Stack spacing={1} sx={{width: '100%'}} marginTop={2} marginBottom={2}>
        {billingServices?.map(s => billingServiceMap[s?.billing_service](s))}
    </Stack>
}

export default BillingServices
import {Avatar, Stack, TextField, useTheme} from "@mui/material";
import Button from "@mui/material/Button";
import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded';
import {useContext, useState, useRef} from "react";
import ConfigContext from "../../../context/ConfigContext";
import Typography from "@mui/material/Typography";
import {isUnderMaxFileSize} from "../../../utils/ValidationUtils";

const ServiceData = ({reference, obj}) => {
    const {baseUrl, MAX_IMG_SIZE} = useContext(ConfigContext)
    const theme = useTheme()
    const [pic, setPic] = useState('')
    const ref = useRef(null)

    const getProfilePic = () => {
        if (pic instanceof File) return URL.createObjectURL(pic)
        if (obj?.image?.startsWith('http')) return obj?.image
        return baseUrl + obj?.image;
    }

    return <form ref={reference}>
        <Stack spacing={1}>
            <Typography variant='caption' color={theme.palette.primary[500]}>Név*</Typography>
            <TextField variant='standard' required maxLength='256' name='name' defaultValue={obj?.name || ''}/>
            <Typography variant='caption' color={theme.palette.primary[500]}>Hossza* (perc)</Typography>
            <TextField variant='standard' helperText='5 vagy annak többszöröse kell, hogy legyen pl.: 10, 30...' inputProps={{ type: 'number', step: 5, required: true }} required name='length' defaultValue={obj?.length || 5}/>
            <Typography variant='caption' color={theme.palette.primary[500]}>kategória rövid leírása (max. 256 karakter)*</Typography>
            <TextField variant='standard' required maxLength='256' name='description' multiline minRows={5} defaultValue={obj?.description || ''}/>
            <input type="hidden"/>
            <Typography variant='caption' color={theme.palette.primary[500]}>Kategória képe*</Typography>
            <Stack direction='row' spacing={2}>
                <Avatar sx={{width: 60, height: 60}} src={getProfilePic()}/>
                <Button variant='outlined' component='label' endIcon={<FileUploadRoundedIcon/>}>Kép feltöltése* <input ref={ref} type="file" name="image" accept='image/*' hidden onChange={event => {
                    const file = event.target.files[0];
                    if (isUnderMaxFileSize(file, MAX_IMG_SIZE)) setPic(file)
                    else {
                        ref.current.value = ''
                        setPic('')
                        alert(`A kép nem lehet nagyobb mint ${MAX_IMG_SIZE} MB`)
                    }
                }}/></Button>
            </Stack>
        </Stack>
    </form>
}

export default ServiceData
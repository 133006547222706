import AuthBase from "../AuthBase";
import {Avatar, Stack, useTheme} from "@mui/material";
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import useCallDataApi from "../../hooks/data";
import {useContext, useEffect, useRef, useState} from "react";
import Typography from "@mui/material/Typography";
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ConfigContext from "../../context/ConfigContext";
import DinoDialog from "../../components/Dialog";
import CategoryData from "./categoryData";
import Button from "@mui/material/Button";
import {getFormDataNative} from "../../utils/FormUtils";
import CircleButton from "../../components/CircleButton";
import {useHistory} from "react-router-dom";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import Loading from "../../components/Loading";
import ErrorContext from "../../context/ErrorContext";


const Category = ({id, name, description, blur, image, numberOfServices, edit, destroy}) => {
    const theme = useTheme()
    const history = useHistory()
    const {baseUrl} = useContext(ConfigContext)

    return <Stack sx={{flexDirection: {xs: 'column', sm: 'column', md: 'row'}}} spacing={2} style={{width: '100%', justifyContent: 'space-between'}}>
        <Stack direction='row' spacing={2}>

            <Avatar src={image.startsWith('http') ? image : baseUrl + image} sx={{
                width: 60,
                height: 60,
                border: '2px solid',
                borderColor: theme.palette.primary[200],
                alignSelf: 'center',
            }}/>
            <div style={{alignSelf: 'center'}}>
                <Typography variant="subtitle1" style={{color: theme.palette.primary[600]}}>{name}</Typography>
                <Typography variant="body2" style={{color: '#536B74'}}>{description.substring(0, 30)}{description?.length > 30 && '...'}</Typography>
            </div>
        </Stack>

        <Stack spacing={1} direction='row' sx={{display: {xs: 'none', sm: 'none', md: 'flex'}}}>
            <Typography variant='button' align='center' noWrap sx={{
                color: theme.palette.primary[600],
                alignSelf: 'center'
            }}>{numberOfServices} szolgáltatás</Typography>
            <CircleButton icon={<DeleteRoundedIcon/>} onClick={destroy}/>
            <CircleButton icon={<EditIcon/>} onClick={edit}/>
            <CircleButton icon={<KeyboardArrowRightIcon/>} onClick={() => {
                history.push(`/service/${id}`)
            }} inverted/>
        </Stack>

        <Stack spacing={1} sx={{display: {xs: 'flex', sm: 'flex', md: 'none'}}}>
            <Button variant='outlined' endIcon={<EditIcon/>} onClick={edit}>Szerkesztés</Button>
            <Button variant='contained' endIcon={<KeyboardArrowRightIcon/>} onClick={() => {
                history.push(`/service/${id}`)
            }}>Megnyitás</Button>
        </Stack>
    </Stack>
}

const Categories = () => {
    const [categories, setCategories] = useState([])
    const [current, setCurrent] = useState(1)
    const [editOpen, setEditOpen] = useState(false)
    const [newOpen, setNewOpen] = useState(false)
    const [deleteOpen, setDeleteOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const {setPictureError} = useContext(ErrorContext)


    const {
        getData: fetchCategories,
        formDataUpdateData: updateCategory,
        deleteData: deleteCategory,
        formDataCreateData: createCategory
    } = useCallDataApi('category')
    const serviceForm = useRef(null)

    const buttons = [{
        name: 'Új kategória hozzáadása',
        props: {
            variant: 'contained',
            size: 'large',
            endIcon: <AddCircleOutlinedIcon/>,
            onClick: () => {
                setNewOpen(true)
            }
        }
    }]

    const filterButtons = [
        {
            name: 'Új kategória',
            props: {
                variant: 'contained',
                size: 'small',
                endIcon: <AddCircleOutlinedIcon/>,
                onClick: () => {
                    setNewOpen(true)
                }
            }
        }
    ]

    const getCategory = (id) => {
        return categories.find(c => c.id === id)
    }

    const newCategory = () => {
        setLoading(true)
        const formDataNative = getFormDataNative(serviceForm.current);
        if (formDataNative) {
            const noPicture = !formDataNative.has('picture')
            if (noPicture) {
                setPictureError(true)
                setLoading(false)
                return false
            }
            createCategory(formDataNative).then(c => {
                if (c) setCategories([...categories, c])
            }).finally(() => setLoading(false))
            return true
        }
        setLoading(false)
        return false
    }

    const editCategory = () => {
        setLoading(true)
        const formDataNative = getFormDataNative(serviceForm.current);
        if (formDataNative) {
            updateCategory(`${current}`, formDataNative).then(c => {
                if (c) {
                    const updated = categories.map(r => {
                        if (r.id !== c.id) return r
                        else return c
                    })
                    setCategories(updated)
                }
            }).finally(() => setLoading(false))
            return true
        }
        else {
            setLoading(false)
            return false
        }

    }

    const removeCategory = (id) => {
        setLoading(true)
        deleteCategory(id).then(() => {
            setCategories([...categories.filter(c => c.id !== id)])
        }).finally(() => setLoading(false))
    }

    useEffect(() => {
        const getData = async () => {
            const cat = await fetchCategories('get_for_business')
            if (cat) setCategories(cat)
        }
        getData()
            .then(() => setLoading(false))
    }, [])

    const deleteActions = <>
        <Button variant='outlined' style={{padding: 15}} onClick={() => setDeleteOpen(false)}>mégsem</Button>
        <Button variant='contained' style={{padding: 15}} onClick={() => {
            setDeleteOpen(false)
            removeCategory(current)
        }} endIcon={<DeleteRoundedIcon/>}>Törlés</Button>
    </>
    const editActions = <>
        <Button variant='outlined' style={{padding: 15}} onClick={() => setEditOpen(false)}>mégsem</Button>
        <Button variant='contained' style={{padding: 15}} onClick={() => {
            if (editCategory()) setEditOpen(false)
        }} endIcon={<EditIcon/>}>Szerkesztés</Button>
    </>
    const newActions = <>
        <Button variant='outlined' style={{padding: 15}} onClick={() => setNewOpen(false)}>mégsem</Button>
        <Button variant='contained' style={{padding: 15}} onClick={() => {
            if (newCategory()) setNewOpen(false)
        }} endIcon={<AddCircleOutlinedIcon/>}>Hozzáadás</Button>
    </>


    return <AuthBase label='Kategóriák' buttons={buttons} filterButtonsMobile={filterButtons}>
        <div className="main">
            <Typography variant='subtitle2' style={{color: '#536B74'}} gutterBottom>{categories?.length} szolgáltatás
                kategória</Typography>
            <div className="white-card">
                <Stack spacing={2}>
                    {categories.map((c, i) =>
                        <Category key={`category_${i}`}
                                  name={c.name}
                                  id={c.id}
                                  description={c.description}
                                  blur={c.blur}
                                  image={c.picture}
                                  numberOfServices={c.number_of_services}
                                  edit={() => {
                                      setCurrent(c.id)
                                      setEditOpen(true)
                                  }}
                                  destroy={() => {
                                      setCurrent(c.id)
                                      setDeleteOpen(true)
                                  }}/>
                    )}
                </Stack>
            </div>
        </div>

        <DinoDialog open={newOpen}
                    title='Új kategória hozzáadása'
                    subtitle='Hozz létre egyedi kategóriákat és ahhoz tartozó szolgáltatásokat egyszerűen. Add meg a kategória nevét és leírását!'
                    actions={newActions}
                    handleClose={() => setNewOpen(false)}>
            <CategoryData reference={serviceForm}/>
        </DinoDialog>

        <DinoDialog open={editOpen}
                    title={`${getCategory(current)?.name} kategória szerkesztése`}
                    subtitle='Add meg a szolgáltatás nevét és leírását!'
                    actions={editActions}
                    handleClose={() => setEditOpen(false)}>
            <CategoryData obj={getCategory(current)} reference={serviceForm}/>
        </DinoDialog>

        <DinoDialog open={deleteOpen}
                    title={`Biztosan kitörli ezt: ${getCategory(current)?.name}`}
                    subtitle='Az összes hozzá tartozó szolgáltatás és foglalás törlődni fog!'
                    actions={deleteActions}
                    handleClose={() => setDeleteOpen(false)}>
        </DinoDialog>
        <Loading isLoading={loading}/>
    </AuthBase>
}

export default Categories
import AuthBase from "../AuthBase";
import {Grid, Stack, TextField, useTheme} from "@mui/material";
import Typography from "@mui/material/Typography";
import TimeRangePicker from "../../components/TimeRangePicker";
import {useEffect, useRef, useState} from "react";
import useCallDataApi from "../../hooks/data";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import workHourTemplate from "./WorkHourTemplate";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {getFormData} from "../../utils/FormUtils";
import DinoDialog from "../../components/Dialog";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import Box from "@mui/material/Box";
import Loading from "../../components/Loading";
import _ from "lodash";


const OpeningHours = () => {
    const [openingHours, setOpeningHours] = useState(workHourTemplate)
    const [editedOpeningHours, setEditedOpeningHours] = useState(workHourTemplate)
    const [leaves, setLeaves] = useState([])
    const [leaveStart, setLeaveStart] = useState(new Date())
    const [leaveEnd, setLeaveEnd] = useState(new Date())
    const [current, setCurrent] = useState(-1)
    const [business, setBusiness] = useState({})
    const [loading, setLoading] = useState(true)
    const [deleteLeaveOpen, setDeleteLeaveOpen] = useState(false)
    const {getData: fetchOpeningHours, updateData: updateOpeningHours} = useCallDataApi('work-hour')
    const {getData: fetchLeaves, createData: createLeave, deleteData: deleteLeave} = useCallDataApi('leave')
    const {getData: fetchBusiness} = useCallDataApi('business')
    const theme = useTheme()
    const leaveForm = useRef(null)

    useEffect(() => {
        const getData = async () => {
            const [w, l, b] = await Promise.all([
                fetchOpeningHours('get_for_business'),
                fetchLeaves('get_for_business'),
                fetchBusiness('get_business')
            ])
            if (b) setBusiness(b)
            if (w) {
                setOpeningHours(w)
                setEditedOpeningHours(_.cloneDeep(w))
            }
            if (l) setLeaves(l)
        }
        getData()
            .catch(r => console.log(r))
            .finally(() => setLoading(false))
    }, [])

    const deleteIconButtonStyle = {
        alignSelf: 'center',
        color: theme.palette.primary[400],
        border: '2px solid',
        borderColor: theme.palette.primary[400]
    }

    const isDifferent = () => {
        return JSON.stringify(editedOpeningHours) !== JSON.stringify(openingHours)
    }

    const addNewOpeningHour = (day) => {
        editedOpeningHours[day] = [...editedOpeningHours[day], {start: '09:00', end: '21:00'}]
        setEditedOpeningHours({...editedOpeningHours})
    }

    const removeOpeningHour = (day, idx) => {
        editedOpeningHours[day].splice(idx, 1)
        setEditedOpeningHours({...editedOpeningHours})
    }

    const editOpeningHour = (start, end, day, idx) => {
        editedOpeningHours[day] = [...editedOpeningHours[day].map((h, i) => {
            if (i === idx) return {...h, start: start, end: end}
            else return h
        })]
        setEditedOpeningHours({...editedOpeningHours})
    }

    const addLeave = () => {
        const data = getFormData(leaveForm.current)
        createLeave({...data, business: business.id}).then(l => setLeaves([...leaves, l]))
    }

    const buttons = [{
        name: 'Változtatások mentése',
        props: {
            endIcon: <SaveRoundedIcon/>,
            variant: 'contained',
            size: 'large',
            disabled: !isDifferent(),
            onClick: () => {
                setLoading(true)
                updateOpeningHours('update_business/', editedOpeningHours)
                    .then(o => {
                        setOpeningHours(o)
                        setEditedOpeningHours(_.cloneDeep(o))
                    })
                    .finally(() => setLoading(false))
            }
        }
    }]

    const deleteActions = <>
        <Button variant='outlined' style={{padding: 15}} onClick={() => setDeleteLeaveOpen(false)}>mégsem</Button>
        <Button variant='contained' style={{padding: 15}} onClick={() => {
            setDeleteLeaveOpen(false)
            removeLeave()
        }} endIcon={<DeleteRoundedIcon/>}>Törlés</Button>
    </>

    const removeLeave = () => {
        deleteLeave(current).then(() => setLeaves([...leaves.filter(l => l.id !== current)]))
    }

    const handleLeaveStartChange = (start) => {
        setLeaveStart(start)
        if (leaveEnd && start > leaveEnd) setLeaveEnd(start)
    }

    const handleLeaveEndChange = (end) => {
        setLeaveEnd(end)
        if (leaveStart && end < leaveStart) setLeaveStart(end)
    }

    return <AuthBase label='Nyitvatartás' buttons={buttons} filterButtonsMobile={buttons}>
        <div className="main">
            <Grid container spacing={2}>

                {/*Opening hours*/}
                <Grid item xs={12}>
                    <div className="white-card">
                        <Typography variant='subtitle1'>Nyitvatartási idő</Typography>
                        <Typography variant='body2' color='#536B74'>Add meg mikor van nyitva vállalkozásod. A
                            kollégáknál csak ebben az idő intevallumban lehet foglalni időpontot!</Typography>
                        <Grid container spacing={2} marginTop={3}>
                            <Grid item xs={12} md={12 / 5}>
                                <Typography variant='subtitle2'>Hétfő</Typography>
                            </Grid>
                            {editedOpeningHours?.monday?.map((h, i) => <Grid key={`monday_${i}_${h.start}_${h.end}`} item xs={12}
                                                                             md={12 / 5}>
                                <TimeRangePicker startTime={h.start} endTime={h.end} day='monday' idx={i}
                                                 editing={editOpeningHour} deleting={removeOpeningHour}/>
                            </Grid>)}
                            <Grid item xs={12} md={12 / 5}>
                                <Button endIcon={<AddCircleOutlineIcon/>} onClick={() => addNewOpeningHour('monday')}
                                        variant='outlined' size='small'>Új idősáv hozzáadása</Button>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} marginTop={3}>
                            <Grid item xs={12} md={12 / 5}>
                                <Typography variant='subtitle2'>Kedd</Typography>
                            </Grid>
                            {editedOpeningHours?.tuesday?.map((h, i) => <Grid key={`tuesday_${i}_${h.start}_${h.end}`} item xs={12}
                                                                              md={12 / 5}>
                                <TimeRangePicker startTime={h.start} endTime={h.end} day='tuesday' idx={i}
                                                 editing={editOpeningHour} deleting={removeOpeningHour}/>
                            </Grid>)}
                            <Grid item xs={12} md={12 / 5}>
                                <Button endIcon={<AddCircleOutlineIcon/>} onClick={() => addNewOpeningHour('tuesday')}
                                        variant='outlined' size='small'>Új idősáv hozzáadása</Button>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} marginTop={3}>
                            <Grid item xs={12} md={12 / 5}>
                                <Typography variant='subtitle2'>Szerda</Typography>
                            </Grid>
                            {editedOpeningHours?.wednesday?.map((h, i) => <Grid key={`wednesday_${i}_${h.start}_${h.end}`} item xs={12}
                                                                                md={12 / 5}>
                                <TimeRangePicker startTime={h.start} endTime={h.end} day='wednesday' idx={i}
                                                 editing={editOpeningHour} deleting={removeOpeningHour}/>
                            </Grid>)}
                            <Grid item xs={12} md={12 / 5}>
                                <Button endIcon={<AddCircleOutlineIcon/>} onClick={() => addNewOpeningHour('wednesday')}
                                        variant='outlined' size='small'>Új idősáv hozzáadása</Button>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} marginTop={3}>
                            <Grid item xs={12} md={12 / 5}>
                                <Typography variant='subtitle2'>Csütörtök</Typography>
                            </Grid>
                            {editedOpeningHours?.thursday?.map((h, i) => <Grid key={`thursday_${i}_${h.start}_${h.end}`} item xs={12}
                                                                               md={12 / 5}>
                                <TimeRangePicker startTime={h.start} endTime={h.end} day='thursday' idx={i}
                                                 editing={editOpeningHour} deleting={removeOpeningHour}/>
                            </Grid>)}
                            <Grid item xs={12} md={12 / 5}>
                                <Button endIcon={<AddCircleOutlineIcon/>} onClick={() => addNewOpeningHour('thursday')}
                                        variant='outlined' size='small'>Új idősáv hozzáadása</Button>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} marginTop={3}>
                            <Grid item xs={12} md={12 / 5}>
                                <Typography variant='subtitle2'>Péntek</Typography>
                            </Grid>
                            {editedOpeningHours?.friday?.map((h, i) => <Grid key={`friday_${i}_${h.start}_${h.end}`} item xs={12}
                                                                             md={12 / 5}>
                                <TimeRangePicker startTime={h.start} endTime={h.end} day='friday' idx={i}
                                                 editing={editOpeningHour} deleting={removeOpeningHour}/>
                            </Grid>)}
                            <Grid item xs={12} md={12 / 5}>
                                <Button endIcon={<AddCircleOutlineIcon/>} onClick={() => addNewOpeningHour('friday')}
                                        variant='outlined' size='small'>Új idősáv hozzáadása</Button>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} marginTop={3}>
                            <Grid item xs={12} md={12 / 5}>
                                <Typography variant='subtitle2'>Szombat</Typography>
                            </Grid>
                            {editedOpeningHours?.saturday?.map((h, i) => <Grid key={`saturday_${i}_${h.start}_${h.end}`} item xs={12}
                                                                               md={12 / 5}>
                                <TimeRangePicker startTime={h.start} endTime={h.end} day='saturday' idx={i}
                                                 editing={editOpeningHour} deleting={removeOpeningHour}/>
                            </Grid>)}
                            <Grid item xs={12} md={12 / 5}>
                                <Button endIcon={<AddCircleOutlineIcon/>} onClick={() => addNewOpeningHour('saturday')}
                                        variant='outlined' size='small'>Új idősáv hozzáadása</Button>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} marginTop={3}>
                            <Grid item xs={12} md={12 / 5}>
                                <Typography variant='subtitle2'>Vasárnap</Typography>
                            </Grid>
                            {editedOpeningHours?.sunday?.map((h, i) => <Grid key={`sunday_${i}_${h.start}_${h.end}`} item xs={12}
                                                                             md={12 / 5}>
                                <TimeRangePicker startTime={h.start} endTime={h.end} day='sunday' idx={i}
                                                 editing={editOpeningHour} deleting={removeOpeningHour}/>
                            </Grid>)}
                            <Grid item xs={12} md={12 / 5}>
                                <Button endIcon={<AddCircleOutlineIcon/>} onClick={() => addNewOpeningHour('sunday')}
                                        variant='outlined' size='small'>Új idősáv hozzáadása</Button>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>

                {/*Leaves*/}
                <Grid item xs={12}>
                    <div className="white-card">
                        <Typography variant='subtitle1'>Céges szabadságok beállítása</Typography>
                        <Typography color='#536B74' variant='button2'>Adj hozzá szabadságokat! A kollégák egyedi
                            szabadságát a kollégák menüpont alatt tudod beállítani!</Typography>
                        {leaves.length === 0 &&
                            <Box sx={{border: '1px solid', borderColor: '#F1F4F5', borderRadius: '16px', marginTop: 2}}>
                                <Stack spacing={2} alignItems='center' justifyContent='center' sx={{padding: 2}}>
                                    <Typography variant='subtitle2' color='#6F8E9A'>Nincsenek szabadságok</Typography>
                                    <Typography variant='body2' color='#6F8E9A'>Adj hozzá új szabadságot az alábbi
                                        gombra kattintva! 👇</Typography>
                                </Stack>
                            </Box>}
                        {leaves.map((l, i) => <Grid container spacing={2} key={`leave_${i}`} sx={{marginTop: 2}}>
                            <Grid item xs={12} md={3}><Typography variant='subtitle2'>{l?.name}</Typography></Grid>
                            <Grid item xs={12} md={3}><Typography variant='button'
                                                                  sx={{color: '#536B74'}}>{l?.from_date} - {l?.end_date}</Typography></Grid>
                            <Grid item xs={12} md={3}><Typography variant='caption' sx={{color: '#38474D'}}>céges
                                szabadság</Typography></Grid>
                            <Grid item xs={12} md={3}>
                                <IconButton style={{...deleteIconButtonStyle, alignSelf: 'center'}} onClick={() => {
                                    setCurrent(l.id)
                                    setDeleteLeaveOpen(true)
                                }}>
                                    <DeleteIcon/>
                                </IconButton>
                            </Grid>
                        </Grid>)}
                        <form ref={leaveForm}>
                            <Grid container spacing={1} marginTop={3}>
                                <Grid item xs={12} md={3}><Stack spacing={1}><Typography variant='caption'
                                                                                         color={theme.palette.primary[500]}>Szabadság
                                    megnevezése</Typography><TextField name='name' variant='standard'/></Stack></Grid>
                                <Grid item xs={12} md={3}><Stack spacing={1}><Typography variant='caption'
                                                                                         color={theme.palette.primary[500]}>Szabadság
                                    kezdete</Typography><LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        value={leaveStart}
                                        onChange={(newValue) => handleLeaveStartChange(newValue)}
                                        minDate={new Date()}
                                        inputFormat="YYYY-MM-DD"
                                        renderInput={(params) => <TextField name='from_date'
                                                                            variant='standard' {...params} />}
                                    />
                                </LocalizationProvider></Stack></Grid>
                                <Grid item xs={12} md={3}><Stack spacing={1}><Typography variant='caption'
                                                                                         color={theme.palette.primary[500]}>szabadság
                                    vége</Typography><LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        value={leaveEnd}
                                        onChange={(newValue) => handleLeaveEndChange(newValue)}
                                        minDate={leaveStart}
                                        inputFormat="YYYY-MM-DD"
                                        renderInput={(params) => <TextField name='end_date'
                                                                            variant='standard' {...params} />}
                                    />
                                </LocalizationProvider></Stack></Grid>
                                <Grid item xs={12} md={3} sx={{display: 'flex', alignItems: 'end'}}><Button
                                    variant='contained' size='small' endIcon={<AddCircleOutlineIcon/>}
                                    onClick={addLeave}>Új szabadság hozzáadása</Button></Grid>
                            </Grid>
                        </form>
                    </div>
                </Grid>
            </Grid>
        </div>


        <DinoDialog open={deleteLeaveOpen}
                    title='Szabadság törlése'
                    subtitle='Biztos vagy benne hogy szeretnéd törölni a szabadságot?'
                    handleClose={() => setDeleteLeaveOpen(false)}
                    actions={deleteActions}>
        </DinoDialog>

        <Loading isLoading={loading}/>

    </AuthBase>
}

export default OpeningHours
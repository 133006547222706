import {Stack, useTheme} from "@mui/material";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import {useHistory} from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {useContext, useEffect, useState} from "react";
import ConfigContext from "../../context/ConfigContext";
import useCallDataApi from "../../hooks/data";
import ReservationContext from "../../context/ReservationContext";
import thousandSeparator from "../../utils/numberUtils";
import CircleButton from "../CircleButton";
import Loading from "../Loading";



const Colleague = ({colleague, price}) => {
    const theme = useTheme()
    const {baseUrl} = useContext(ConfigContext)

    return <div style={{display: 'flex', gap: '20px'}}>
        <Avatar src={baseUrl + colleague?.profile_picture} sx={{width: 70, height: 70, alignSelf: 'center', background: theme.palette.primary[200]}}/>
        <div className="text-container">
            <Typography align="left" variant="subtitle1">{colleague?.last_name} {colleague?.first_name}</Typography>
            <Typography align="left" variant="body2">{colleague?.title}</Typography>
            <Typography align="left" variant="body1">{thousandSeparator(price)} Ft</Typography>
        </div>
    </div>
}


const SelectPrice = () => {
    const {getData: fetchPrices} = useCallDataApi('sp-prices')
    const {selected, next} = useContext(ReservationContext)
    const [loading, setLoading] = useState(true)
    const [prices, setPrices] = useState([])

    useEffect(() => {
        if (selected?.service) fetchPrices(`get_for_service/?service=${selected?.service?.id}`)
            .then(p => setPrices(p))
            .finally(() => setLoading(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected?.service])

    const proceed = (p) => next({...selected, price: p})

    return <div className="data-container">
        <Stack spacing={{xs: 2}}>
            {prices.map((p, i) =>
                <Box className="data-select" key={`data_${i}`}>
                    <Stack direction="row" spacing={{xs: 2}} sx={{justifyContent: 'space-between'}}>
                        <Colleague colleague={p?.colleague} price={p?.price}/>
                        <CircleButton icon={<NavigateNextIcon/>} onClick={() => proceed(p)}/>
                    </Stack>
                </Box>)}
        </Stack>

        <Loading isLoading={loading}/>
    </div>
}

export default SelectPrice
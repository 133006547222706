import AuthBase from "../AuthBase";
import {Button, Checkbox, Grid, Stack, TextField, useTheme} from "@mui/material";
import Typography from "@mui/material/Typography";
import useCallDataApi from "../../hooks/data";
import {useEffect, useState} from "react";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MessageTemplateTemplate from "./MessageTemplateTemplate";
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import TinyEditor from "../../components/TinyEditor";
import Loading from "../../components/Loading";
import {HashLink} from "react-router-hash-link";

const Notifications = () => {
    const [messageVariables, setMessageVariables] = useState([])
    const [messageTemplate, setMessageTemplate] = useState(MessageTemplateTemplate)
    const [editedMessageTemplate, setEditedMessageTemplate] = useState(MessageTemplateTemplate)
    const {getData: fetchMessageVariables} = useCallDataApi('message-variable')
    const {getData: fetchMessageTemplate, updateData: updateMessageTemplate} = useCallDataApi('message-template')
    const theme = useTheme()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const getData = async () => {
            const mv = fetchMessageVariables()
            const mt = fetchMessageTemplate('get_for_business')
            const [messageVar, messageTemp] = await Promise.all([mv, mt])
            if (messageTemp) setEditedMessageTemplate(messageTemp)
            if (messageTemp) setMessageTemplate(messageTemp)
            if (messageVar) setMessageVariables(messageVar)
            setLoading(false)
        }
        getData().catch(r => console.log(r))
    }, [])


    const editMessageTemplate = () => {
        updateMessageTemplate(`${messageTemplate?.id}/`, editedMessageTemplate).then(m => {
            setMessageTemplate(m)
            setEditedMessageTemplate(m)
        })
    }

    const isDifferentMessageTemplate = () => {
        return messageTemplate !== editedMessageTemplate
    }

    const buttons = [{
        name: 'Változtatások mentése',
        props: {
            endIcon: <SaveRoundedIcon/>,
            variant: 'contained',
            disabled: !isDifferentMessageTemplate(),
            onClick: () => {
                editMessageTemplate()
            }
        }
    }]

    const checkBoxStyle = {display: 'flex', justifyContent: 'center', alignItems: 'center'}

    const setField = (fieldName, value) => {
        editedMessageTemplate[fieldName] = value
        setEditedMessageTemplate({...editedMessageTemplate})
    }

    const scrollWithOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -80;
        window.scrollTo({top: yCoordinate + yOffset, behavior: 'smooth'});
    }

    return <AuthBase label='Értesítések' buttons={buttons} filterButtonsMobile={buttons}>
        <Loading isLoading={loading}/>
        <div className="main">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <div className="white-card-sp">
                        <Grid container sx={{background: '#F3F5F7', padding: 3, borderRadius: '4px'}}>
                            <Grid item xs={2}/>
                            <Grid item xs={5}>
                                <Stack>
                                    <Typography align='center' variant='subtitle2'>Ügyfelek értesítése</Typography>
                                    <HashLink to='/notifications#client-notification'
                                              scroll={el => scrollWithOffset(el)} style={{textDecoration: 'none', textAlign: 'center'}}>
                                        <Button>Sablonok szerkesztése</Button>
                                    </HashLink>
                                </Stack>
                                <Grid container>
                                    <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}><Typography
                                        align='center' variant='caption' color='#000'>e-mail</Typography></Grid>
                                    <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}><Typography
                                        align='center' variant='caption' color='#000'>sms</Typography></Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={5}>
                                <Stack>
                                    <Typography align='center' variant='subtitle2'>Kollégák értesítése</Typography>
                                    <HashLink to='/notifications#colleague-notification'
                                              scroll={el => scrollWithOffset(el)} style={{textDecoration: 'none', textAlign: 'center'}}>
                                        <Button>Sablonok szerkesztése</Button>
                                    </HashLink>
                                </Stack>
                                <Grid container>
                                    <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}><Typography
                                        align='center' variant='caption' color='#000'>e-mail</Typography></Grid>
                                    <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}><Typography
                                        align='center' variant='caption' color='#000'>sms</Typography></Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container
                              sx={{marginTop: 3, borderRadius: '4px', border: '1px solid', borderColor: '#E2E8EB'}}>
                            <Grid item
                                  sx={{background: '#F3F5F7', display: 'flex', alignItems: 'center', padding: '8px'}}
                                  xs={12 / 5}><Typography variant='button' className='table-heading' color='#000'>Foglalás
                                megerősítése</Typography></Grid>
                            <Grid item sx={checkBoxStyle} xs={12 / 5}><Checkbox
                                checked={editedMessageTemplate?.notify_customer_confirmation_email}
                                onChange={(event) => setEditedMessageTemplate({
                                    ...editedMessageTemplate,
                                    notify_customer_confirmation_email: event.target.checked
                                })}/></Grid>
                            <Grid item sx={checkBoxStyle} xs={12 / 5}><Checkbox
                                checked={editedMessageTemplate?.notify_customer_confirmation_sms}

                                onChange={(event) => setEditedMessageTemplate({
                                    ...editedMessageTemplate,
                                    notify_customer_confirmation_sms: event.target.checked
                                })}/></Grid>
                            <Grid item sx={checkBoxStyle} xs={12 / 5}><Checkbox
                                checked={editedMessageTemplate?.notify_colleague_confirmation_email}
                                onChange={(event) => setEditedMessageTemplate({
                                    ...editedMessageTemplate,
                                    notify_colleague_confirmation_email: event.target.checked
                                })}/></Grid>
                            <Grid item sx={checkBoxStyle} xs={12 / 5}><Checkbox
                                checked={editedMessageTemplate?.notify_colleague_confirmation_sms}

                                onChange={(event) => setEditedMessageTemplate({
                                    ...editedMessageTemplate,
                                    notify_colleague_confirmation_sms: event.target.checked
                                })}/></Grid>
                        </Grid>
                        <Grid container
                              sx={{marginTop: 3, borderRadius: '4px', border: '1px solid', borderColor: '#E2E8EB'}}>
                            <Grid item
                                  sx={{background: '#F3F5F7', display: 'flex', alignItems: 'center', padding: '8px'}}
                                  xs={12 / 5}><Typography variant='button' className='table-heading'
                                                          color='#000'>Lemondás</Typography></Grid>
                            <Grid item sx={checkBoxStyle} xs={12 / 5}><Checkbox
                                checked={editedMessageTemplate?.notify_customer_resignation_email}
                                onChange={(event) => setEditedMessageTemplate({
                                    ...editedMessageTemplate,
                                    notify_customer_resignation_email: event.target.checked
                                })}/></Grid>
                            <Grid item sx={checkBoxStyle} xs={12 / 5}><Checkbox
                                checked={editedMessageTemplate?.notify_customer_resignation_sms}

                                onChange={(event) => setEditedMessageTemplate({
                                    ...editedMessageTemplate,
                                    notify_customer_resignation_sms: event.target.checked
                                })}/></Grid>
                            <Grid item sx={checkBoxStyle} xs={12 / 5}><Checkbox
                                checked={editedMessageTemplate?.notify_colleague_resignation_email}
                                onChange={(event) => setEditedMessageTemplate({
                                    ...editedMessageTemplate,
                                    notify_colleague_resignation_email: event.target.checked
                                })}/></Grid>
                            <Grid item sx={checkBoxStyle} xs={12 / 5}><Checkbox
                                checked={editedMessageTemplate?.notify_colleague_resignation_sms}

                                onChange={(event) => setEditedMessageTemplate({
                                    ...editedMessageTemplate,
                                    notify_colleague_resignation_sms: event.target.checked
                                })}/></Grid>
                        </Grid>
                        <Grid container
                              sx={{marginTop: 3, borderRadius: '4px', border: '1px solid', borderColor: '#E2E8EB'}}>
                            <Grid item
                                  sx={{background: '#F3F5F7', display: 'flex', alignItems: 'center', padding: '8px'}}
                                  xs={12 / 5}><Typography variant='button' className='table-heading' color='#000'>Foglalás
                                módosítás</Typography></Grid>
                            <Grid item sx={checkBoxStyle} xs={12 / 5}><Checkbox
                                checked={editedMessageTemplate?.notify_customer_amendment_email}
                                onChange={(event) => setEditedMessageTemplate({
                                    ...editedMessageTemplate,
                                    notify_customer_amendment_email: event.target.checked
                                })}/></Grid>
                            <Grid item sx={checkBoxStyle} xs={12 / 5}><Checkbox
                                checked={editedMessageTemplate?.notify_customer_amendment_sms}

                                onChange={(event) => setEditedMessageTemplate({
                                    ...editedMessageTemplate,
                                    notify_customer_amendment_sms: event.target.checked
                                })}/></Grid>
                            <Grid item sx={checkBoxStyle} xs={12 / 5}><Checkbox
                                checked={editedMessageTemplate?.notify_colleague_amendment_email}
                                onChange={(event) => setEditedMessageTemplate({
                                    ...editedMessageTemplate,
                                    notify_colleague_amendment_email: event.target.checked
                                })}/></Grid>
                            <Grid item sx={checkBoxStyle} xs={12 / 5}><Checkbox
                                checked={editedMessageTemplate?.notify_colleague_amendment_sms}

                                onChange={(event) => setEditedMessageTemplate({
                                    ...editedMessageTemplate,
                                    notify_colleague_amendment_sms: event.target.checked
                                })}/></Grid>
                        </Grid>
                        <Grid container
                              sx={{marginTop: 3, borderRadius: '4px', border: '1px solid', borderColor: '#E2E8EB'}}>
                            <Grid item
                                  sx={{background: '#F3F5F7', display: 'flex', alignItems: 'center', padding: '8px'}}
                                  xs={12 / 5}><Typography variant='button' className='table-heading'
                                                          color='#000'>Emlékeztető (1 órával előtte)</Typography></Grid>
                            <Grid item sx={checkBoxStyle} xs={12 / 5}><Checkbox
                                checked={editedMessageTemplate?.notify_customer_reminder_email}
                                onChange={(event) => setEditedMessageTemplate({
                                    ...editedMessageTemplate,
                                    notify_customer_reminder_email: event.target.checked
                                })}/></Grid>
                            <Grid item sx={checkBoxStyle} xs={12 / 5}><Checkbox
                                checked={editedMessageTemplate?.notify_customer_reminder_sms}

                                onChange={(event) => setEditedMessageTemplate({
                                    ...editedMessageTemplate,
                                    notify_customer_reminder_sms: event.target.checked
                                })}/></Grid>
                            <Grid item sx={checkBoxStyle} xs={12 / 5}><Checkbox
                                checked={editedMessageTemplate?.notify_colleague_reminder_email}
                                onChange={(event) => setEditedMessageTemplate({
                                    ...editedMessageTemplate,
                                    notify_colleague_reminder_email: event.target.checked
                                })}/></Grid>
                            <Grid item sx={checkBoxStyle} xs={12 / 5}><Checkbox
                                checked={editedMessageTemplate?.notify_colleague_reminder_sms}

                                onChange={(event) => setEditedMessageTemplate({
                                    ...editedMessageTemplate,
                                    notify_colleague_reminder_sms: event.target.checked
                                })}/></Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className="white-card">
                        <Typography variant='subtitle1'>Értesítésekhez használható változók</Typography>
                        <Grid container spacing={2} marginTop={2}>
                            {messageVariables.map((m, i) => <Grid key={`mv_${i}`} item xs={12} md={6}>
                                <Stack direction='row' alignItems='center' spacing={1}>
                                    <Button variant='contained'
                                            sx={{
                                                color: theme.palette.primary[600],
                                                background: theme.palette.primary[100],
                                                padding: '8px 16px'
                                            }}
                                            endIcon={<ContentCopyIcon/>}
                                            onClick={() => navigator.clipboard.writeText(m?.value)}>
                                        {m?.name}
                                    </Button>
                                    <Typography variant='caption' color='#38474D'> - {m?.description}</Typography>
                                </Stack>
                            </Grid>)}
                        </Grid>
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <Typography id='client-notification' variant='subtitle1'>Ügyfelek értesítései</Typography>
                    <Typography variant='body1' color='#536B74'>Itt tudod szerkeszteni az ügyfeleknek kiküldött
                        értesítések szövegeit. A pontosabb értesítés érdekében használd a fennti változókat az
                        értesítések szövegében!</Typography>
                </Grid>

                <Grid item xs={12}>
                    <div className="white-card">
                        <Typography variant='subtitle2'>Foglalás megerősítése</Typography>
                        <Grid container spacing={3} marginTop={2}>
                            <Grid item xs={12} md={6}>
                                <Stack spacing={2}>
                                    <Typography variant='caption' color={theme.palette.primary[500]}>e-mail címsor (max.
                                        255 karakter)</Typography>
                                    <TextField variant='standard'
                                               value={editedMessageTemplate?.reservation_confirmation_email_title}
                                               onChange={(event) => setEditedMessageTemplate({
                                                   ...editedMessageTemplate,
                                                   reservation_confirmation_email_title: event.target.value
                                               })}/>
                                    <Typography variant='caption' color={theme.palette.primary[500]}>e-mail
                                        szövege</Typography>
                                    <TinyEditor initialValue={editedMessageTemplate?.reservation_confirmation_email}
                                                fieldName='reservation_confirmation_email' setter={setField}/>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Stack spacing={2}>
                                    <Typography variant='caption' color={theme.palette.primary[500]}>sms címsor (max.
                                        255 karakter)</Typography>
                                    <TextField variant='standard' multiline minRows={4}
                                               value={editedMessageTemplate?.reservation_confirmation_sms}

                                               onChange={(event) => setEditedMessageTemplate({
                                                   ...editedMessageTemplate,
                                                   reservation_confirmation_sms: event.target.value
                                               })}/>
                                </Stack>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <div className="white-card">
                        <Typography variant='subtitle2'>Foglalás lemondása</Typography>
                        <Grid container spacing={3} marginTop={2}>
                            <Grid item xs={12} md={6}>
                                <Stack spacing={2}>
                                    <Typography variant='caption' color={theme.palette.primary[500]}>e-mail címsor (max.
                                        255 karakter)</Typography>
                                    <TextField variant='standard'
                                               value={editedMessageTemplate?.reservation_resignation_email_title}
                                               onChange={(event) => setEditedMessageTemplate({
                                                   ...editedMessageTemplate,
                                                   reservation_resignation_email_title: event.target.value
                                               })}/>
                                    <Typography variant='caption' color={theme.palette.primary[500]}>e-mail
                                        szövege</Typography>
                                    <TinyEditor initialValue={editedMessageTemplate?.reservation_resignation_email}
                                                fieldName='reservation_resignation_email' setter={setField}/>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Stack spacing={2}>
                                    <Typography variant='caption' color={theme.palette.primary[500]}>sms címsor (max.
                                        255 karakter)</Typography>
                                    <TextField variant='standard' multiline minRows={4}
                                               value={editedMessageTemplate?.reservation_resignation_sms}

                                               onChange={(event) => setEditedMessageTemplate({
                                                   ...editedMessageTemplate,
                                                   reservation_resignation_sms: event.target.value
                                               })}/>
                                </Stack>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <div className="white-card">
                        <Typography variant='subtitle2'>Foglalás módosítása</Typography>
                        <Grid container spacing={3} marginTop={2}>
                            <Grid item xs={12} md={6}>
                                <Stack spacing={2}>
                                    <Typography variant='caption' color={theme.palette.primary[500]}>e-mail címsor (max.
                                        255 karakter)</Typography>
                                    <TextField variant='standard'
                                               value={editedMessageTemplate?.reservation_amendment_email_title}
                                               onChange={(event) => setEditedMessageTemplate({
                                                   ...editedMessageTemplate,
                                                   reservation_amendment_email_title: event.target.value
                                               })}/>
                                    <Typography variant='caption' color={theme.palette.primary[500]}>e-mail
                                        szövege</Typography>
                                    <TinyEditor initialValue={editedMessageTemplate?.reservation_amendment_email}
                                                fieldName='reservation_amendment_email' setter={setField}/>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Stack spacing={2}>
                                    <Typography variant='caption' color={theme.palette.primary[500]}>sms címsor (max.
                                        255 karakter)</Typography>
                                    <TextField variant='standard' multiline minRows={4}
                                               value={editedMessageTemplate?.reservation_amendment_sms}

                                               onChange={(event) => setEditedMessageTemplate({
                                                   ...editedMessageTemplate,
                                                   reservation_amendment_sms: event.target.value
                                               })}/>
                                </Stack>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <div className="white-card">
                        <Typography variant='subtitle2'>Foglalási emlékeztető</Typography>
                        <Grid container spacing={3} marginTop={2}>
                            <Grid item xs={12} md={6}>
                                <Stack spacing={2}>
                                    <Typography variant='caption' color={theme.palette.primary[500]}>e-mail címsor (max.
                                        255 karakter)</Typography>
                                    <TextField variant='standard'
                                               value={editedMessageTemplate?.reservation_reminder_email_title}
                                               onChange={(event) => setEditedMessageTemplate({
                                                   ...editedMessageTemplate,
                                                   reservation_reminder_email_title: event.target.value
                                               })}/>
                                    <Typography variant='caption' color={theme.palette.primary[500]}>e-mail
                                        szövege</Typography>
                                    <TinyEditor initialValue={editedMessageTemplate?.reservation_reminder_email}
                                                fieldName='reservation_reminder_email' setter={setField}/>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Stack spacing={2}>
                                    <Typography variant='caption' color={theme.palette.primary[500]}>sms címsor (max.
                                        255 karakter)</Typography>
                                    <TextField variant='standard' multiline minRows={4}
                                               value={editedMessageTemplate?.reservation_reminder_sms}

                                               onChange={(event) => setEditedMessageTemplate({
                                                   ...editedMessageTemplate,
                                                   reservation_reminder_sms: event.target.value
                                               })}/>
                                </Stack>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <Typography id='colleague-notification' variant='subtitle1'>Kollégák értesítései</Typography>
                    <Typography variant='body1' color='#536B74'>Itt tudod szerkeszteni az kollégáknak kiküldött
                        értesítések szövegeit. A pontosabb értesítés érdekében használd a fennti változókat az
                        értesítések szövegében!</Typography>
                </Grid>

                <Grid item xs={12}>
                    <div className="white-card">
                        <Typography variant='subtitle2'>Foglalás megerősítése</Typography>
                        <Grid container spacing={3} marginTop={2}>
                            <Grid item xs={12} md={6}>
                                <Stack spacing={2}>
                                    <Typography variant='caption' color={theme.palette.primary[500]}>e-mail címsor (max.
                                        255 karakter)</Typography>
                                    <TextField variant='standard'
                                               value={editedMessageTemplate?.colleague_reservation_confirmation_email_title}
                                               onChange={(event) => setEditedMessageTemplate({
                                                   ...editedMessageTemplate,
                                                   colleague_reservation_confirmation_email_title: event.target.value
                                               })}/>
                                    <Typography variant='caption' color={theme.palette.primary[500]}>e-mail
                                        szövege</Typography>
                                    <TinyEditor initialValue={editedMessageTemplate?.colleague_reservation_confirmation_email}
                                                fieldName='colleague_reservation_confirmation_email' setter={setField}/>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Stack spacing={2}>
                                    <Typography variant='caption' color={theme.palette.primary[500]}>sms címsor (max.
                                        255 karakter)</Typography>
                                    <TextField variant='standard' multiline minRows={4}
                                               value={editedMessageTemplate?.colleague_reservation_confirmation_sms}

                                               onChange={(event) => setEditedMessageTemplate({
                                                   ...editedMessageTemplate,
                                                   colleague_reservation_confirmation_sms: event.target.value
                                               })}/>
                                </Stack>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <div className="white-card">
                        <Typography variant='subtitle2'>Foglalás lemondása</Typography>
                        <Grid container spacing={3} marginTop={2}>
                            <Grid item xs={12} md={6}>
                                <Stack spacing={2}>
                                    <Typography variant='caption' color={theme.palette.primary[500]}>e-mail címsor (max.
                                        255 karakter)</Typography>
                                    <TextField variant='standard'
                                               value={editedMessageTemplate?.colleague_reservation_resignation_email_title}
                                               onChange={(event) => setEditedMessageTemplate({
                                                   ...editedMessageTemplate,
                                                   colleague_reservation_resignation_email_title: event.target.value
                                               })}/>
                                    <Typography variant='caption' color={theme.palette.primary[500]}>e-mail
                                        szövege</Typography>
                                    <TinyEditor initialValue={editedMessageTemplate?.colleague_reservation_resignation_email}
                                                fieldName='colleague_reservation_resignation_email' setter={setField}/>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Stack spacing={2}>
                                    <Typography variant='caption' color={theme.palette.primary[500]}>sms címsor (max.
                                        255 karakter)</Typography>
                                    <TextField variant='standard' multiline minRows={4}
                                               value={editedMessageTemplate?.colleague_reservation_resignation_sms}

                                               onChange={(event) => setEditedMessageTemplate({
                                                   ...editedMessageTemplate,
                                                   colleague_reservation_resignation_sms: event.target.value
                                               })}/>
                                </Stack>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <div className="white-card">
                        <Typography variant='subtitle2'>Foglalás módosítása</Typography>
                        <Grid container spacing={3} marginTop={2}>
                            <Grid item xs={12} md={6}>
                                <Stack spacing={2}>
                                    <Typography variant='caption' color={theme.palette.primary[500]}>e-mail címsor (max.
                                        255 karakter)</Typography>
                                    <TextField variant='standard'
                                               value={editedMessageTemplate?.colleague_reservation_amendment_email_title}
                                               onChange={(event) => setEditedMessageTemplate({
                                                   ...editedMessageTemplate,
                                                   colleague_reservation_amendment_email_title: event.target.value
                                               })}/>
                                    <Typography variant='caption' color={theme.palette.primary[500]}>e-mail
                                        szövege</Typography>
                                    <TinyEditor initialValue={editedMessageTemplate?.colleague_reservation_amendment_email}
                                                fieldName='colleague_reservation_amendment_email' setter={setField}/>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Stack spacing={2}>
                                    <Typography variant='caption' color={theme.palette.primary[500]}>sms címsor (max.
                                        255 karakter)</Typography>
                                    <TextField variant='standard' multiline minRows={4}
                                               value={editedMessageTemplate?.colleague_reservation_amendment_sms}

                                               onChange={(event) => setEditedMessageTemplate({
                                                   ...editedMessageTemplate,
                                                   colleague_reservation_amendment_sms: event.target.value
                                               })}/>
                                </Stack>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <div className="white-card">
                        <Typography variant='subtitle2'>Foglalási emlékeztető</Typography>
                        <Grid container spacing={3} marginTop={2}>
                            <Grid item xs={12} md={6}>
                                <Stack spacing={2}>
                                    <Typography variant='caption' color={theme.palette.primary[500]}>e-mail címsor (max.
                                        255 karakter)</Typography>
                                    <TextField variant='standard'
                                               value={editedMessageTemplate?.colleague_reservation_reminder_email_title}
                                               onChange={(event) => setEditedMessageTemplate({
                                                   ...editedMessageTemplate,
                                                   colleague_reservation_reminder_email_title: event.target.value
                                               })}/>
                                    <Typography variant='caption' color={theme.palette.primary[500]}>e-mail
                                        szövege</Typography>
                                    <TinyEditor initialValue={editedMessageTemplate?.colleague_reservation_reminder_email}
                                                fieldName='colleague_reservation_reminder_email' setter={setField}/>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Stack spacing={2}>
                                    <Typography variant='caption' color={theme.palette.primary[500]}>sms címsor (max.
                                        255 karakter)</Typography>
                                    <TextField variant='standard' multiline minRows={4}
                                               value={editedMessageTemplate?.colleague_reservation_reminder_sms}

                                               onChange={(event) => setEditedMessageTemplate({
                                                   ...editedMessageTemplate,
                                                   colleague_reservation_reminder_sms: event.target.value
                                               })}/>
                                </Stack>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>

            </Grid>
        </div>
    </AuthBase>
}

export default Notifications
import {Avatar, Stack, TextField, useTheme} from "@mui/material";
import Button from "@mui/material/Button";
import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded';
import {useContext, useRef, useState} from "react";
import ConfigContext from "../../context/ConfigContext";
import Typography from "@mui/material/Typography";
import {isUnderMaxFileSize} from "../../utils/ValidationUtils";

const ExtraItemData = ({reference, obj}) => {
    const {baseUrl, MAX_IMG_SIZE} = useContext(ConfigContext)
    const theme = useTheme()
    const [pic, setPic] = useState(obj?.image || '')
    const ref = useRef(null)

    const getProfilePic = () => {
        if (pic instanceof File) return URL.createObjectURL(pic)
        if (obj?.image?.startsWith('http')) return obj?.image
        return baseUrl + obj?.image;
    }

    return <form ref={reference}>
        <Stack spacing={1}>
            <Typography variant='caption' color={theme.palette.primary[500]}>Név*</Typography>
            <TextField variant='standard' name='name' required maxLength='256' defaultValue={obj?.name || ''}/>
            <Typography variant='caption' color={theme.palette.primary[500]}>Tétel rövid leírása (max. 256 karakter)*</Typography>
            <TextField variant='standard' name='description' multiline minRows={5} required maxLength='256' defaultValue={obj?.description || ''}/>
            <Typography variant='caption' color={theme.palette.primary[500]}>Ár*</Typography>
            <TextField variant='standard' name='price' required maxLength='256' type='number' defaultValue={obj?.price || ''}/>
            <input type="hidden"/>
            <Typography variant='caption' color={theme.palette.primary[500]}>Tétel képe*</Typography>
            <Stack direction='row' spacing={2}>
                <Avatar sx={{width: 60, height: 60}} src={getProfilePic()} loading="true"/>
                <Button variant='outlined' component='label' endIcon={<FileUploadRoundedIcon/>}>Kép feltöltése* <input ref={ref} type="file" id='image' name="image" accept='image/*' hidden onChange={event => {
                    const file = event.target.files[0];
                    if (isUnderMaxFileSize(file, MAX_IMG_SIZE)) setPic(file)
                    else {
                        ref.current.value = ''
                        setPic('')
                        alert(`A kép nem lehet nagyobb mint ${MAX_IMG_SIZE} MB`)
                    }
                    // noinspection JSCheckFunctionSignatures
                }}/></Button>
            </Stack>
        </Stack>
    </form>
}

export default ExtraItemData
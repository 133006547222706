import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import {Drawer, useTheme} from "@mui/material";
import {useHistory} from "react-router-dom";
import {HashLink} from "react-router-hash-link";
import greenLogo from "../assets/logos/DinoBooking_logo_oldalt_felirat_01.png"
import {useContext} from "react";
import AuthContext from "../context/AuthContext";

const pages = [
    {name: 'Kezdőlap', link: '/'},
]

function DinoWizardAppBar() {
    const {logoutUser} = useContext(AuthContext)
    const theme = useTheme();
    const history = useHistory()

    const style = {
        background: theme.palette.background.paper,
        color: theme.palette.text.secondary,
    }

    const menuIconStyle = {
        backgroundColor: theme.palette.primary.main,
        borderRadius: '30px',
        color: 'white',
    }

    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const toggleNavMenu = (event) => {
        setAnchorElNav(!Boolean(anchorElNav) ? event.currentTarget : null);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const scrollWithOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -117;
        window.scrollTo({top: yCoordinate + yOffset, behavior: 'smooth'});
    }

    return (
        <AppBar position="sticky" sx={style}>
            <Container maxWidth="xl">

                <Toolbar disableGutters>
                    <Box sx={{flexGrow: 0}}>
                        <Tooltip title="Dinobooking">
                            <IconButton sx={{p: 0}} onClick={() => history.push('/')}>
                                <img src={greenLogo} alt="Dinobooking logo" width="100px"/>
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Box sx={{display: {xs: 'flex', md: 'none'}, marginLeft: 'auto', zIndex: '11'}}>
                        <div style={menuIconStyle}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={toggleNavMenu}
                                color="inherit"
                            >
                                {!anchorElNav ? <MenuIcon/> : <CloseIcon/>}
                            </IconButton>
                        </div>
                        <Drawer
                            anchor={'top'}
                            open={Boolean(anchorElNav)}
                            onClose={toggleNavMenu}
                            ModalProps={{
                                keepMounted: true,
                            }}
                            BackdropProps={{invisible: true}}
                            sx={{
                                '& .MuiDrawer-paper': {
                                    boxSizing: 'border-box',
                                    width: '100%',
                                    height: '80%',
                                    marginTop: '56px'
                                },
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                zIndex: '10'
                            }}
                        >
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                                height: '100%',
                                margin: 'auto',
                                gap: '48px'
                            }}>
                                {pages.map((page) => (
                                    <MenuItem key={page.name + 'mobile'} onClick={handleCloseNavMenu}>
                                        <HashLink to={page.link} scroll={el => scrollWithOffset(el)}
                                                  style={{textDecoration: 'none', color: theme.palette.primary[600]}}>
                                            <Typography textAlign="center" variant="subtitle2">{page.name}</Typography>
                                        </HashLink>
                                    </MenuItem>
                                ))}
                                <MenuItem key='mobile_logout' onClick={() => {
                                    handleCloseNavMenu()
                                    logoutUser()
                                    history.push('/login')
                                }}>
                                        <Typography textAlign="center" variant="subtitle2" color={theme.palette.primary[600]}>Kijelentkezés</Typography>
                                </MenuItem>
                            </div>

                        </Drawer>

                    </Box>

                    <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}, justifyContent: 'flex-end'}}>
                        <HashLink to="/" scroll={el => scrollWithOffset(el)}
                                  style={{textDecoration: 'none', display: 'flex'}}>
                            <Button sx={{my: 2, display: 'block'}}
                                    style={{alignSelf: "center", color: theme.palette.primary[700]}}>
                                Kezdőlap
                            </Button>
                        </HashLink>
                        <Button variant='outlined' sx={{margin: '14px'}} onClick={() => {
                            logoutUser()
                            history.push('/login')
                        }}>Kijelentkezés</Button>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default DinoWizardAppBar;
import AuthBase from "../AuthBase";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {Avatar, Grid, Stack, TextField, useTheme} from "@mui/material";
import {useContext, useEffect, useRef, useState} from "react";
import useCallDataApi from "../../hooks/data";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ConfigContext from "../../context/ConfigContext";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import {Link} from "react-router-dom";
import DinoDialog from "../../components/Dialog";
import {getFormData} from "../../utils/FormUtils";
import Loading from "../../components/Loading";
import AuthContext from "../../context/AuthContext";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";


const Colleagues = () => {
    const [colleagues, setColleagues] = useState([])
    const [newColleagueOpen, setNewColleagueOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const {getData: fetchColleagues} = useCallDataApi('colleague')
    const {postData: register} = useCallDataApi('')
    const theme = useTheme()
    const {baseUrl} = useContext(ConfigContext)
    const {subscriptionPlan, role} = useContext(AuthContext)
    const newColleagueEmailForm = useRef(null)

    const buttons = [{
        name: 'Új kolléga hozzáadása',
        props: {
            variant: 'contained',
            endIcon: <AddCircleOutlineIcon/>,
            disabled: subscriptionPlan !== 'multiple' || role === 'employee',
            onClick: () => {
                setNewColleagueOpen(true)
            },

        }
    }]

    const filterButtons = [
        {
            name: 'Új kolléga hozzáadása',
            props: {
                variant: 'contained',
                endIcon: <AddCircleOutlinedIcon/>,
                disabled: subscriptionPlan !== 'multiple' || role === 'employee',
                onClick: () => {
                    setNewColleagueOpen(true)
                }
            }
        }
    ]

    useEffect(() => {
        fetchColleagues('get_for_business')
            .then(c => {
                if (c) setColleagues(c)
            })
            .finally(() => setLoading(false))
    }, [])

    const newColleague = () => {
        const data = getFormData(newColleagueEmailForm.current)
        register('register_existing/', data).then(r => console.log(r))
    }

    const newColleagueActions = <>
        <Button variant='outlined' style={{padding: 15}} onClick={() => setNewColleagueOpen(false)}>mégsem</Button>
        <Button variant='contained' style={{padding: 15}} onClick={() => {
            setNewColleagueOpen(false)
            newColleague()
        }} endIcon={<AddCircleOutlineIcon/>}>Kolléga hozzáadása</Button>
    </>

    return <AuthBase label='Kollégák' buttons={buttons} filterButtonsMobile={filterButtons}>
        <div className="main">
            <Typography variant='subtitle2' style={{color: '#536B74'}} gutterBottom>{colleagues.length} kolléga</Typography>
            <Grid container spacing={2}>
                {colleagues.map((c, i) => <Grid key={`colleage_${i}`} item xs={12} md={3}>
                    <div className="white-card">
                        <Stack spacing={2} style={{alignItems: 'center'}}>
                            <Avatar src={baseUrl + c.profile_picture} sx={{width: 90, height: 90}}/>
                            <Typography variant='subtitle2'>{c.name}</Typography>
                            <Typography variant='body1' style={{color: '#536B74'}}>{c.title}</Typography>
                            <Button variant='outlined' component={Link} to={`/colleague/${c?.id}`} endIcon={<EditRoundedIcon/>} style={{color: theme.palette.primary[400], padding: '15px', border: '2px solid'}}>Szerkesztés</Button>
                        </Stack>
                    </div>
                </Grid>)}
            </Grid>
        </div>

        <DinoDialog open={newColleagueOpen}
                    handleClose={() => setNewColleagueOpen(false)}
                    title='Új kolléga hozzáadása' subtitle='Adja meg a kolléga e-mail címét és küldünk neki egy regisztrációs linket.'
                    actions={newColleagueActions}>
            <form ref={newColleagueEmailForm}>
                <Stack spacing={1}>
                    <Typography variant='caption' color={theme.palette.primary[500]}>E-mail</Typography>
                    <TextField name='email' variant='standard'/>
                </Stack>
            </form>
        </DinoDialog>

        <Loading isLoading={loading}/>
    </AuthBase>
}

export default Colleagues
import AuthBase from "../AuthBase";
import {useQuery} from "../../hooks/hooks";
import {fromBase64} from "js-base64";
import Typography from "@mui/material/Typography";
import {Grid, Stack} from "@mui/material";
import Button from "@mui/material/Button";
import YouTubeIcon from '@mui/icons-material/YouTube';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import ReplayRoundedIcon from '@mui/icons-material/ReplayRounded';
import coolDino from '../../assets/dinos/019-cool.png'
import sadDino from '../../assets/dinos/035-crying.png'
import {Link} from "react-router-dom";
import {useContext} from "react";
import AuthContext from "../../context/AuthContext";


const PaymentOver = () => {
    let query = useQuery()
    const simpleResponse = JSON.parse(fromBase64(query.get('r')))
    const {setSetupComplete} = useContext(AuthContext)

    const result = {
        SUCCESS: 'Sikeres tranzakció',
        FAIL: 'Sikertelen tranzakció',
        CANCEL: 'Megszakított tranzakció',
        TIMEOUT: 'Időtúllépés',
    }

    const success = <>
        <Stack spacing={2}>
            <Typography variant='subtitle1' color='#38474D'>Sikeres tranzakcíó!</Typography>
            <Typography variant='body2' color='#ff0000'>Köszönjük, hogy minket választottál! Fiókod beállítása eltarthat
                pár percig, amennyiben valamelyik funkció nem működik, kérjük jelentkezz be újra. Addig is tekintsd meg
                az oktatóvideónkat a youtube-on.</Typography>
        </Stack>

        <Grid container spacing={2} marginTop={2}>
            <Grid item xs={6} md={3} style={{display: 'flex', alignItems: 'center'}}>
                <Button variant='contained' component={Link} to='/' startIcon={<DashboardRoundedIcon/>}>Irány a
                    vezérlőpult!</Button>
            </Grid>
            <Grid item xs={6} md={3} style={{display: 'flex', alignItems: 'center'}}>
                <Button variant='contained' onClick={() => window.open('https://www.youtube.com/playlist?list=PLDNdrieGF1f_OJw11osuTIRKUDnWUHw8x', '_blank').focus()}
                        startIcon={<YouTubeIcon/>}>Oktatóvideó</Button>
            </Grid>
            <Grid item xs={12} md={6} style={{display: 'flex', alignItems: 'center', justifyContent: 'end'}}>
                <img src={coolDino} alt="Cool dino" style={{width: 200}}/>
            </Grid>
        </Grid>
    </>

    const fail = <>
        <Stack spacing={2}>
            <Typography variant='subtitle1' color='#38474D'>Sikertelen tranzakció!</Typography>
            <Typography variant='body2' color='#38474D'>Kérjük, ellenőrizd a tranzakció során megadott adatok
                helyességét. Amennyiben minden adatot helyesen adtál meg, a visszautasítás okának kivizsgálása érdekében
                kérjük, lépj kapcsolatba kártyakibocsátó bankjával.</Typography>
        </Stack>
        <Grid container spacing={2} marginTop={2}>
            <Grid item xs={6} md={3} style={{display: 'flex', alignItems: 'center'}}>
                <Button variant='contained' component={Link} to='/card-registration' startIcon={<ReplayRoundedIcon/>}>Próbáld
                    újra</Button>
            </Grid>
            <Grid item xs={12} md={6} style={{display: 'flex', alignItems: 'center', justifyContent: 'end'}}>
                <img src={sadDino} alt="Sad dino" style={{width: 200}}/>
            </Grid>
        </Grid>
    </>

    const cancel = <>
        <Stack spacing={2}>
            <Typography variant='subtitle1' color='#38474D'>Megszakított fizetés!</Typography>
            <Typography variant='body2' color='#38474D'>A tranzakciót megszakítottad, a dinobooking használatához kérjük próbáld meg újra.</Typography>
        </Stack>
        <Grid container spacing={2} marginTop={2}>
            <Grid item xs={6} md={3} style={{display: 'flex', alignItems: 'center'}}>
                <Button variant='contained' component={Link} to='/card-registration' startIcon={<ReplayRoundedIcon/>}>Próbáld
                    újra</Button>
            </Grid>
            <Grid item xs={12} md={6} style={{display: 'flex', alignItems: 'center', justifyContent: 'end'}}>
                <img src={sadDino} alt="Sad dino" style={{width: 200}}/>
            </Grid>
        </Grid>
    </>

    const timeout = <>
        <Stack spacing={2}>
            <Typography variant='subtitle1' color='#38474D'>Időtúllépés!</Typography>
            <Typography variant='body2' color='#38474D'>A tranzakció időtúllépés miatt nem teljesült, kérjük próbáld meg újra.</Typography>
        </Stack>
        <Grid container spacing={2} marginTop={2}>
            <Grid item xs={6} md={3} style={{display: 'flex', alignItems: 'center'}}>
                <Button variant='contained' component={Link} to='/card-registration' startIcon={<ReplayRoundedIcon/>}>Próbáld
                    újra</Button>
            </Grid>
            <Grid item xs={12} md={6} style={{display: 'flex', alignItems: 'center', justifyContent: 'end'}}>
                <img src={sadDino} alt="Sad dino" style={{width: 200}}/>
            </Grid>
        </Grid>
    </>

    const resultMap = {
        SUCCESS: success,
        FAIL: fail,
        CANCEL: cancel,
        TIMEOUT: timeout
    }

    if (simpleResponse?.e === 'SUCCESS') {
        setSetupComplete(true)
        localStorage.setItem('setup_complete', 'true')
    }

    return <AuthBase label={result[simpleResponse.e]}>
        <div className="main">
            <div className="white-card">
                <Typography variant='headline6'>Tranzakció azonosító: {simpleResponse.t}</Typography>
                {resultMap[simpleResponse.e]}
            </div>
        </div>
    </AuthBase>
}

export default PaymentOver